import React, { useState, useEffect, useCallback } from 'react';
import {
    Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress,
    useMediaQuery, useTheme, Box, styled, Button
} from '@mui/material';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import AssignedCoursesDropdown from '../components/TeacherCompleteCourseDropdown';
import attainmentService from '../api/attainmentService';

const StickyTableCell = styled(TableCell)(({ theme, position }) => ({
    position: 'sticky',
    left: position === 'first' ? 0 : position === 'second' ? 60 : 140,
    background: theme.palette.background.paper,
    zIndex: 5
  }));

const CourseAttainmentPage = () => {
    const [selectedCourseId, setSelectedCourseId] = useState('');
    const [students, setStudents] = useState([]);
    const [cloAttainment, setCLOAttainment] = useState({});
    const [ploAttainment, setPLOAttainment] = useState({});
    const [overallScores, setOverallScores] = useState({});
    const [courseData, setCourseData] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const fetchStudents = useCallback(async () => {
        if (selectedCourseId) {
            setLoading(true);
            try {
                const response = await attainmentService.getCourseStudents(selectedCourseId);
                setStudents(response.data || []);
            } catch (error) {
                console.error('Error fetching students:', error);
            }
            setLoading(false);
        }
    }, [selectedCourseId]);

    useEffect(() => {
        fetchStudents();
        if (selectedCourseId) {
            fetchAttainmentData(selectedCourseId);
        }
    }, [selectedCourseId, fetchStudents]);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const fetchAttainmentData = async (courseId) => {
        setLoading(true);
        setError('');
        try {
            const { data } = await attainmentService.fetchCourseAttainmentData(courseId);
            setCLOAttainment(data.cloAttainment || {});
            setPLOAttainment(data.ploAttainment || {});
            setOverallScores(data.overallScores || {});
            setCourseData(data.courseData || {});
        } catch (error) {
            setError('Failed to fetch attainment data');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleCourseChange = (event) => {
        setSelectedCourseId(event.target.value);
    };

    const exportPDF = () => {
        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'pt',
            format: 'a4'
        });

        const title = 'Air University';
        const subtitle = "Subject Wise CLO's and PLO's Attainment Report";
        const currentDate = new Date();
        const dateStr = currentDate.toDateString();
        const timeStr = currentDate.toLocaleTimeString();

        doc.setFontSize(10);
        doc.text(`Date: ${dateStr} Time: ${timeStr}`, doc.internal.pageSize.getWidth() - 200, 20);

        doc.setFont('helvetica', 'bold');
        doc.setFontSize(24);
        doc.text(title, doc.internal.pageSize.getWidth() / 2, 40, { align: 'center' });

        doc.setFont('helvetica', 'bold');
        doc.setFontSize(18);
        doc.text(subtitle, doc.internal.pageSize.getWidth() / 2, 70, { align: 'center' });

        const offerClass = courseData.batchid;
        const offerCourse = `${courseData.crscode} - ${courseData.crsname}`;

        doc.setFont('helvetica', 'bold');
        doc.setFontSize(12);
        doc.text(`Offer Class: ${offerClass}`, 40, 100);
        doc.text(`Offer Subject: ${offerCourse}`, 40, 115);

        let startY = 140;

        const drawTableWithHeader = (header, data, startY, label) => {
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(14);
            doc.setTextColor(100);
            doc.text(label, 40, startY + 15);

            const headers = [["SNO", "Reg. ID", "Student Name", "Score", "Grade", ...Object.keys(data[Object.keys(data)[0] || {}]).map(id => `${id}`)]];

            const tableData = students.map((student, index) => [
                index + 1,
                student.stuid,
                student.stuname,
                overallScores[student.stuid]?.score.toFixed(2) || 'N/A',
                overallScores[student.stuid]?.grade || 'N/A',
                ...Object.values(data[student.stuid] || {}).map(detail => `${detail.percentage}% (${detail.status})`)
            ]);

            doc.autoTable({
                head: headers,
                body: tableData,
                startY: startY + 20,
                theme: 'grid',
                headStyles: { fillColor: [22, 160, 133], textColor: 'white', fontSize: 10, fontStyle: 'bold' },
                margin: { horizontal: 40 },
                styles: {
                    overflow: 'linebreak',
                    fontSize: 8,
                    cellPadding: 4,
                    textColor: [0, 0, 0],
                    minCellHeight: 10,
                    halign: 'center',
                    valign: 'middle',
                    lineColor: [0, 0, 0],
                    lineWidth: 0.5
                },
                columnStyles: {
                    0: { cellWidth: 30 },
                    1: { cellWidth: 70 },
                    2: { cellWidth: 100 },
                    3: { cellWidth: 40 },
                    4: { cellWidth: 40 },
                    text: { cellWidth: 'auto' }
                },
                willDrawCell: (data) => {
                    if (data.row.section === 'body') {
                        const cellContent = String(data.cell.raw).trim();
                        const rowData = data.row.raw;
                        if (cellContent.match(/\b(Fail|F)\b/)) {
                            doc.setFillColor(255, 153, 153);
                            doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'F');
                        } else if (data.column.index === 3 && rowData[4] === "F") {
                            doc.setFillColor(255, 153, 153);
                            doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'F');
                        }
                    }
                }
            });

            return doc.lastAutoTable.finalY;
        };

        startY = drawTableWithHeader(cloAttainment, cloAttainment, startY, "CLO Attainment") + 10;

        drawTableWithHeader(ploAttainment, ploAttainment, startY, "PLO Attainment");

        doc.save('course_attainment_report.pdf');
    };

    const renderAttainmentTable = (attainmentData, type) => (
        <TableContainer component={Paper} elevation={12} sx={{
            borderRadius: 2,
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
            border: '1px solid rgba(0,0,0,0.12)'
        }}>
            <Table aria-label={`${type} attainment table`}>
                <TableHead sx={{ bgcolor: '#e0e0e0' }}>
                    <TableRow sx={{ '& th': { fontWeight: 'bold' } }}>
                        <StickyTableCell align="center" sx={{ bgcolor: '#e0e0e0' }} position="first" style={{ whiteSpace: 'nowrap' }}>Sr. No.</StickyTableCell>
                        <StickyTableCell align="center" sx={{ bgcolor: '#e0e0e0' }} position="second" style={{ whiteSpace: 'nowrap' }}>Reg. ID</StickyTableCell>
                        <StickyTableCell align="center" sx={{ bgcolor: '#e0e0e0' }} position="third" style={{ whiteSpace: 'nowrap' }}>Student Name</StickyTableCell>
                        <TableCell align="center">Score</TableCell>
                        <TableCell align="center">Grade</TableCell>
                        {Object.keys(attainmentData[Object.keys(attainmentData)[0] || {}]).map((id) => (
                            <TableCell key={id} align="center">{type} {id}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {students.map((student, index) => (
                        <TableRow key={student.stuid}>
                            <StickyTableCell align="center" position="first" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{index + 1}</StickyTableCell>
                            <StickyTableCell align="center" position="second" style={{ borderRight: '1px solid rgba(0,0,0,0.12)', whiteSpace: 'nowrap' }}>{student.stuid}</StickyTableCell>
                            <StickyTableCell align="center" position="third" style={{ borderRight: '1px solid rgba(0,0,0,0.12)', whiteSpace: 'nowrap' }}>{student.stuname}</StickyTableCell>
                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)', backgroundColor: overallScores[student.stuid]?.grade === 'F' ? 'rgba(255, 153, 153, 0.5)' : 'inherit' }}>{overallScores[student.stuid]?.score.toFixed(2) || 'N/A'}</TableCell>
                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)', backgroundColor: overallScores[student.stuid]?.grade === 'F' ? 'rgba(255, 153, 153, 0.5)' : 'inherit' }}>{overallScores[student.stuid]?.grade || 'N/A'}</TableCell>
                            {Object.entries(attainmentData[student.stuid] || {}).map(([id, detail], index) => (
                                <TableCell key={index} align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)', whiteSpace: 'nowrap', backgroundColor: detail.status === 'Fail' ? 'rgba(255, 153, 153, 0.5)' : 'inherit' }}>{`${detail.percentage}% (${detail.status})`}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    return (
        <Box display="flex" justifyContent="center" sx={{ mt: 4, mb: 4, ml: matches ? '280px' : 0, width: matches ? `calc(100% - 280px)` : '100%' }}>
            <Container maxWidth="lg">
                <Typography variant="h4" sx={{ mb: 3 }}>Course Attainment</Typography>
                <AssignedCoursesDropdown onChange={handleCourseChange} value={selectedCourseId} />

                {loading && <CircularProgress />}
                {error && <Typography color="error">{error}</Typography>}

                {selectedCourseId && (
                    <>
                        <Box display="flex" justifyContent="flex-end" mb={2} mt={2}>
                            <Button
                                variant="contained"
                                onClick={exportPDF}
                                style={{ backgroundColor: '#6c63ff', color: 'white' }}
                                sx={{ borderRadius: 2, boxShadow: '0 3px 5px 2px rgba(105, 140, 255, .3)' }}
                            >
                                Export to PDF
                            </Button>
                        </Box>
                        <Typography variant="h6" sx={{ mt: 2 }}>CLO Attainment</Typography>
                        {Object.keys(cloAttainment).length > 0 ? renderAttainmentTable(cloAttainment) : <Typography>No CLO Attainment Data Available</Typography>}

                        <Typography variant="h6" sx={{ mt: 2 }}>PLO Attainment</Typography>
                        {Object.keys(ploAttainment).length > 0 ? renderAttainmentTable(ploAttainment) : <Typography>No PLO Attainment Data Available</Typography>}
                    </>
                )}
            </Container>
        </Box>
    );
};

export default CourseAttainmentPage;
