import React, { useState } from 'react';
import {
    Container, Typography, Button, TextField, Box, Card, CardContent, IconButton, InputAdornment, useMediaQuery, useTheme, Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import updatePasswordService from '../api/updatePasswordService';
import { useLocationState } from '../context/LocationContext';

const UpdatePasswordAdminPage = () => {
    const [userType, setUserType] = useState('administrator');
    const [userId, setUserId] = useState('');
    const [formData, setFormData] = useState({
        newPassword: '',
        confirmPassword: ''
    });
    const [formError, setFormError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { locationState } = useLocationState();
    const adminId = locationState?.userId;

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const handleChange = (e) => {
        if (formError) {
            setFormError('');
        }
        const { name, value } = e.target;
        if (name === 'userType') {
            setUserType(value);
            if (value === 'administrator') {
                setUserId('');
            }
        } else if (name === 'userId') {
            setUserId(value);
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const showUserIdField = userType === 'teacher' || userType === 'student';

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
        return passwordRegex.test(password);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.newPassword !== formData.confirmPassword) {
            setFormError('Passwords do not match');
            return;
        }
        try {
            if (userType === 'administrator') {
                await updatePasswordService.updateAdminPassword(adminId, formData.newPassword);
            } else if (userType === 'teacher') {
                await updatePasswordService.updateTeacherPassword(userId, formData.newPassword);
            } else if (userType === 'student') {
                await updatePasswordService.updateStudentPassword(userId, formData.newPassword);
            }
            setFormData({ newPassword: '', confirmPassword: '' });
            setUserId('');
            setShowPassword(false);
            setShowConfirmPassword(false);
        } catch (error) {
            setFormError(error.response.data || 'Failed to update password');
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const allFieldsFilled = Object.values(formData).every(val => val) && validatePassword(formData.newPassword) && (userType === 'administrator' || userId);

    return (
        <Box display="flex" justifyContent="center" sx={{ mt: 4, mb: 4, ml: matches ? '280px' : 0, width: matches ? `calc(100% - 280px)` : '100%' }}>
            <Container maxWidth="sm">
                <Typography variant="h4" sx={{ mb: 3 }}>Update Password</Typography>
                <Card elevation={12} sx={{ mb: 3, p: 2, borderRadius: 2 }}>
                    <CardContent>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel id="user-type-label">Role</InputLabel>
                                <Select
                                    labelId="user-type-label"
                                    id="userType"
                                    value={userType}
                                    name="userType"
                                    label="Role"
                                    onChange={handleChange}
                                >
                                    <MenuItem value="administrator">Administrator</MenuItem>
                                    <MenuItem value="student">Student</MenuItem>
                                    <MenuItem value="teacher">Teacher</MenuItem>
                                </Select>
                            </FormControl>
                            {showUserIdField && (
                                <TextField
                                    name="userId"
                                    label="User ID"
                                    value={userId}
                                    onChange={handleChange}
                                    required
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}

                            <TextField
                                name="newPassword"
                                label="New Password"
                                type={showPassword ? 'text' : 'password'}
                                value={formData.newPassword}
                                onChange={handleChange}
                                required
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={togglePasswordVisibility}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                error={formData.newPassword && !validatePassword(formData.newPassword)}
                                helperText={formData.newPassword && !validatePassword(formData.newPassword) && 'Password must be at least 8 characters long and include a number and a special character'}
                            />
                            <TextField
                                name="confirmPassword"
                                label="Confirm Password"
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                required
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={toggleConfirmPasswordVisibility}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Button type="submit" variant="contained" disabled={!allFieldsFilled} sx={{ mt: 2 }} style={{ backgroundColor: '#6c63ff', color: 'white' }}>
                                Update Password
                            </Button>
                            {formError && (
                                <Typography color="error" sx={{ mt: 2 }}>{formError}</Typography>
                            )}
                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </Box>
    );
};

export default UpdatePasswordAdminPage;
