import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const defineCLOs = (formData) => {
    return axios.post(`${baseURL}/clos`, formData);
};

const getAssignedCourseBatchId = (assignedcrsid) => {
    return axios.get(`${baseURL}/clos/assignedcourses/batch/${assignedcrsid}`);
};

const getBatchProgId = (batchid) => {
    return axios.get(`${baseURL}/clos/batches/program/${batchid}`);
};

const getPLOsByProgramId = (progid) => {
    return axios.get(`${baseURL}/clos/programs/plos/${progid}`);
};

const cloService = {
    defineCLOs,
    getAssignedCourseBatchId,
    getBatchProgId,
    getPLOsByProgramId
};

export default cloService;
