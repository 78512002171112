import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const getTaxonomyDomains = () => {
    return axios.get(`${baseURL}/taxonomydomains`);
};

const taxonomyDomainAPI = {
    getTaxonomyDomains
};

export default taxonomyDomainAPI;
