import React, { useState, useEffect } from 'react';
import {
    Container, Typography, Card, CardContent, CircularProgress, useMediaQuery, useTheme, Box
} from '@mui/material';
import AssignedCoursesDropdown from '../components/TeacherCompleteCourseDropdown';
import cqiService from '../api/suggestionService';

const CqiSuggestionPage = () => {
    const [selectedCourseId, setSelectedCourseId] = useState('');
    const [suggestions, setSuggestions] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        // Fetch courses or any other necessary data when the component mounts
    }, []);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const handleCourseChange = async (event) => {
        const courseId = event.target.value;
        setSelectedCourseId(courseId);
        setLoading(true);
        setError('');

        try {
            const response = await cqiService.fetchCqiSuggestion(courseId);
            if (response.data.error) {
                setError(response.data.error);
                setSuggestions('');
            } else {
                setSuggestions(response.data);
                setError('');
            }
        } catch (error) {
            setError('Failed to fetch CQI suggestion.');
        }

        setLoading(false);
    };

    return (
        <Box display="flex" justifyContent="center" sx={{ mt: 4, mb: 4, ml: matches ? '280px' : 0, width: matches ? `calc(100% - 280px)` : '100%' }}>
            <Container maxWidth="lg">
                <Typography variant="h4" sx={{ mb: 3 }}>CQI Suggestion</Typography>
                <AssignedCoursesDropdown onChange={handleCourseChange} value={selectedCourseId} />
                {error && <Typography color="error">{error}</Typography>}
                {loading ? (
                    <CircularProgress />
                ) : (
                    suggestions && (
                        <Card elevation={12} sx={{ mb: 3, mt: 2, p: 2, borderRadius: 2 }}>
                            <CardContent>
                                <div dangerouslySetInnerHTML={{ __html: suggestions }} />
                            </CardContent>
                        </Card>
                    )
                )}
            </Container>
        </Box>
    );
};

export default CqiSuggestionPage;
