import React, { useState, useEffect, useCallback } from 'react';
import {
  Container, Typography, CircularProgress, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, useMediaQuery,
  useTheme, Box
} from '@mui/material';
import { useLocationState } from '../context/LocationContext';
import attainmentService from '../api/attainmentService';

const ProgramAttainmentPage = () => {
  const [batchId, setBatchId] = useState('');
  const [ploAttainment, setPLOAttainment] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { locationState } = useLocationState();
  const studentId = locationState?.userId;

  const fetchAttainmentData = useCallback(async (batchId) => {
    if (!batchId) return;

    setLoading(true);
    setPLOAttainment({});
    setError('');
    try {
      const response = await attainmentService.fetchProgramAttainmentDataForStudent(batchId, studentId);
      if (response.data.error) {
        setError(response.data.error);
        setPLOAttainment({});
      } else {
        setPLOAttainment(response.data || {});
        setError('');
      }
    } catch (error) {
      setError('Failed to fetch program attainment data');
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [studentId]);

  useEffect(() => {
    const fetchStudentBatch = async () => {
      setLoading(true);
      try {
        const response = await attainmentService.getStudentBatch(studentId);
        if (response.data) {
          setBatchId(response.data);
          fetchAttainmentData(response.data);
        }
      } catch (error) {
        setError('Failed to fetch batch data');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchStudentBatch();
  }, [studentId, fetchAttainmentData]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const renderPLOAttainmentTable = () => (
    <TableContainer component={Paper} elevation={12} sx={{
      borderRadius: 2,
      boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
      border: '1px solid rgba(0,0,0,0.12)'
    }}>
      <Table aria-label="PLO attainment table">
      <TableHead sx={{ bgcolor: '#e0e0e0' }}>
      <TableRow sx={{ '& th': { fontWeight: 'bold' } }}>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Sr. No.</TableCell>
            <TableCell style={{ whiteSpace: 'nowrap' }}>PLO ID</TableCell>
            <TableCell align="center">Percentage</TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(ploAttainment).map(([id, { percentage, status }], index) => (
            <TableRow key={id}>
              <TableCell style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{index + 1}</TableCell>
              <TableCell style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{id}</TableCell>
              <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{`${percentage}%`}</TableCell>
              <TableCell align="center">{status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Box display="flex" justifyContent="center" sx={{ mt: 4, mb: 4, ml: matches ? '280px' : 0, width: matches ? `calc(100% - 280px)` : '100%' }}>
    <Container maxWidth="lg">
      <Typography variant="h4" sx={{ mb: 3 }}>My Program Attainment</Typography>

      {loading && <CircularProgress />}
      {error && <Typography color="error">{error}</Typography>}

      {!error && batchId && Object.keys(ploAttainment).length > 0 && renderPLOAttainmentTable()}
    </Container>
    </Box>
  );
};

export default ProgramAttainmentPage;
