import React, { useState, useEffect } from 'react';
import LoginForm from '../components/LoginForm';
import { Grid, Box, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const LoginPage = () => {
  const [backgroundImageUrl, setBackgroundImageUrl] = useState(`https://source.unsplash.com/featured/?programming,${Date.now()}`);

  useEffect(() => {
    const interval = setInterval(() => {
      setBackgroundImageUrl(`https://source.unsplash.com/featured/?programming,${Date.now()}`);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const theme = createTheme({
    palette: {
      background: {
        default: '#f4f5f7',
      },
      primary: {
        main: '#556cd6',
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            color: 'white',
          }
        }
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid item xs={false} sm={4} md={7} sx={{
          backgroundImage: `url(${backgroundImageUrl})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
        }}>
          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 4,
          }}>
            <Box>
              <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold', mb: 1 }}>
                Welcome to OBE Management System
              </Typography>
              <Typography variant="subtitle1">
                Enhancing learning through Outcome-based education.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Box} display="flex" alignItems="center" justifyContent="center">
          <LoginForm />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default LoginPage;
