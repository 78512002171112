import React, { useState, useEffect, useCallback } from 'react';
import {
    Container, Typography, Button, TextField, Box, Card, CardContent, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useMediaQuery,
    useTheme, styled, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { Add, Close, Edit, Delete } from '@mui/icons-material';
import AssignedCoursesDropdown from '../components/AssignedCoursesDropdown';
import markEntryService from '../api/markEntryService';
import attainmentService from '../api/attainmentService';

const assessmentTypes = ['QUIZ', 'ASSIGNMENT', 'MID_TERM', 'FINAL_TERM', 'PROJECT'];

const StickyTableCell = styled(TableCell)(({ theme, position }) => ({
    position: 'sticky',
    left: position === 'first' ? 0 : position === 'second' ? 60 : 140,
    background: theme.palette.background.paper,
    zIndex: 5
}));

const MarkEntryPage = () => {
    const [selectedCourseId, setSelectedCourseId] = useState('');
    const [students, setStudents] = useState([]);
    const [clos, setCLOs] = useState([]);
    const [assessmentDetails, setAssessmentDetails] = useState([]);
    const [formData, setFormData] = useState({
        assessmentType: '',
        number: '',
        cloId: '',
        totalMarks: '',
        studentMarks: []
    });
    const [error, setError] = useState('');
    const [isReadyToEnterMarks, setIsReadyToEnterMarks] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [editingInstance, setEditingInstance] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [triggerFetchCourses, setTriggerFetchCourses] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [instanceToDelete, setInstanceToDelete] = useState(null);

    const fetchAssessmentDetails = useCallback(async () => {
        try {
            const assessmentDetailsData = await markEntryService.getCourseAssessmentDetails(selectedCourseId);
            setAssessmentDetails(assessmentDetailsData.data);
        } catch (error) {
            console.error('Failed to fetch assessment details:', error);
            setError('Failed to fetch new assessment details.');
        }
    }, [selectedCourseId]);

    useEffect(() => {
        const checkPreconditions = async () => {
            if (selectedCourseId) {
                try {
                    const weightages = await markEntryService.areWeightagesDefined(selectedCourseId);
                    const closResponse = await markEntryService.areCLOsDefine(selectedCourseId);
                    const studentsData = await attainmentService.getCourseStudents(selectedCourseId);
                    const closData = await markEntryService.getCLOsByAssignedCrsId(selectedCourseId);
                    await fetchAssessmentDetails();

                    if (!weightages.data && !closResponse.data) {
                        throw new Error('First set assessment weightages and define CLOs.');
                    } else if (!weightages.data) {
                        throw new Error('First set assessment weightages.');
                    } else if (!closResponse.data) {
                        throw new Error('First define CLOs.');
                    }

                    setStudents(studentsData.data);
                    setCLOs(closData.data.map(clo => ({ ...clo, label: `${clo.cloid} - ${clo.clodescription}` })));

                    setFormData(prev => ({
                        ...prev,
                        studentMarks: studentsData.data.map(student => ({ stuid: student.stuid, obtainedmarks: '' }))
                    }));
                    setIsReadyToEnterMarks(true);
                    setError('');
                } catch (error) {
                    setIsReadyToEnterMarks(false);
                    setError(error.message);
                }
            } else {
                setIsReadyToEnterMarks(false);
                setShowForm(false);
            }
        };

        checkPreconditions();
    }, [selectedCourseId, fetchAssessmentDetails]);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const handleCourseChange = (e) => {
        const newSelectedCourseId = e.target.value;
        setSelectedCourseId(newSelectedCourseId);
        if (newSelectedCourseId !== selectedCourseId && showForm) {
            setFormData({
                assessmentType: '',
                number: '',
                cloId: '',
                totalMarks: '',
                studentMarks: students.map(student => ({ stuid: student.stuid, obtainedmarks: '' }))
            });
            setShowForm(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
        setError('');
        setErrorMessage('');
    };

    const handleStudentMarkChange = (stuid, obtainedMarks) => {
        const obtained = Number(obtainedMarks);
        if (obtained < 0 || obtained > Number(formData.totalMarks)) {
            setError(`Obtained Marks must be between 0 and ${formData.totalMarks}.`);
        } else {
            setError('');
            setFormData(prev => {
                const newStudentMarks = prev.studentMarks.map(mark =>
                    mark.stuid === stuid ? { ...mark, obtainedmarks: obtainedMarks } : mark
                );
                return { ...prev, studentMarks: newStudentMarks };
            });
        }
    };

    const toggleFormVisibility = () => {
        setShowForm(!showForm);
        if (showForm) {
            setFormData({
                assessmentType: '',
                number: '',
                cloId: '',
                totalMarks: '',
                studentMarks: students.map(student => ({ stuid: student.stuid, obtainedmarks: '' }))
            });
            setEditingInstance(null);
        }
    };

    const handleEdit = (instanceName) => {
        const selectedInstanceDetails = assessmentDetails.filter(detail => detail.instancename === instanceName);
        if (selectedInstanceDetails.length > 0) {
            const firstDetail = selectedInstanceDetails[0];
            setFormData({
                assessmentType: instanceName.split(/(\d+)/)[0],
                number: instanceName.split(/(\d+)/)[1],
                cloId: firstDetail.cloid,
                totalMarks: firstDetail.totalmarks,
                studentMarks: students.map(student => {
                    const studentDetail = selectedInstanceDetails.find(detail => detail.stuid === student.stuid);
                    return {
                        stuid: student.stuid,
                        obtainedmarks: studentDetail ? studentDetail.obtainedmarks : ''
                    };
                })
            });
            setShowForm(true);
            setEditingInstance(instanceName);
        }
    };

    const handleDeleteInstance = async () => {
        try {
            setAssessmentDetails(prev => prev.filter(detail => detail.instancename !== instanceToDelete));
            setConfirmDelete(false);
            await markEntryService.deleteAssessmentInstance({ instancename: instanceToDelete, assignedcrsid: selectedCourseId });
        } catch (error) {
            setErrorMessage('Failed to delete the assessment instance.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const allMarksEntered = formData.studentMarks.every(mark => mark.obtainedmarks !== '');
        if (!formData.assessmentType || !formData.number || !formData.cloId || !formData.totalMarks || !allMarksEntered) {
            setError('Please fill out all fields and ensure all student marks are entered.');
            return;
        }

        const instancename = `${formData.assessmentType}${formData.number}`;

        try {
            if (editingInstance) {
                await markEntryService.updateAssessmentInstance({
                    cloid: formData.cloId,
                    totalmarks: formData.totalMarks,
                    assignedcrsid: selectedCourseId,
                    instancename,
                    studentMarks: formData.studentMarks
                });
            } else {
                await markEntryService.createAssessmentInstance({
                    instancename,
                    cloid: formData.cloId,
                    totalmarks: formData.totalMarks,
                    assignedcrsid: selectedCourseId,
                    assessmenttype: formData.assessmentType,
                    studentMarks: formData.studentMarks
                });
            }

            await fetchAssessmentDetails();

            setFormData({
                assessmentType: '',
                number: '',
                cloId: '',
                totalMarks: '',
                studentMarks: students.map(student => ({ stuid: student.stuid, obtainedmarks: '' }))
            });
            setShowForm(false);
            setError('');
            setEditingInstance(null);
        } catch (error) {
            console.log(error.response.data)
            setError(error.response.data);
        }
    };

    const uniqueAssessmentDetails = assessmentDetails.reduce((unique, item) => {
        return unique.find(u => u.instancename === item.instancename) ? unique : [...unique, item];
    }, []);

    const markCourseAsComplete = async () => {
        try {
            await markEntryService.markCourseCompleted(selectedCourseId);
            setErrorMessage('');

            setSelectedCourseId('');
            setStudents([]);
            setCLOs([]);
            setFormData({
                assessmentType: '',
                number: '',
                cloId: '',
                totalMarks: '',
                studentMarks: []
            });
            setIsReadyToEnterMarks(false);
            setShowForm(false);
            refreshCoursesList();

        } catch (error) {
            setErrorMessage(error.response?.data || error.message);
        }
    };

    const refreshCoursesList = () => {
        setTriggerFetchCourses(prev => !prev);
    };

    const validateMarks = (marks) => {
        return marks >= 1 && marks <= 100;
    };

    return (
        <Box display="flex" justifyContent="center" sx={{ mt: 4, mb: 4, ml: matches ? '280px' : 0, width: matches ? `calc(100% - 280px)` : '100%' }}>
            <Container maxWidth="lg">
                <Typography variant="h4" sx={{ mb: 3 }}>Marks Entry</Typography>
                <AssignedCoursesDropdown onChange={handleCourseChange} value={selectedCourseId} triggerFetch={triggerFetchCourses} />
                {!isReadyToEnterMarks && error && (
                    <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>
                )}

                {isReadyToEnterMarks && (
                    <>
                        <Box display="flex" justifyContent="flex-end" mb={2} mt={2}>
                            <Button
                                startIcon={showForm ? <Close /> : <Add />}
                                variant="contained"
                                style={{ backgroundColor: '#6c63ff', color: 'white' }}
                                onClick={toggleFormVisibility}
                                sx={{ borderRadius: 2, boxShadow: '0 3px 5px 2px rgba(105, 140, 255, .3)' }}
                            >
                                {showForm ? 'Cancel' : 'Enter Marks'}
                            </Button>
                        </Box>

                        {showForm && (
                            <Card elevation={12} sx={{ mb: 3, p: 2, borderRadius: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" sx={{ mb: 2 }}>
                                        {editingInstance ? 'Update Marks of Assessment' : 'Enter Marks of Assessment'}
                                    </Typography>
                                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                        <TextField
                                            select
                                            id="assessment-type"
                                            name="assessmentType"
                                            label="Assessment"
                                            value={formData.assessmentType}
                                            onChange={handleChange}
                                            required
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            disabled={!!editingInstance}
                                        >
                                            {assessmentTypes.map(type => (
                                                <MenuItem key={type} value={type}>{type}</MenuItem>
                                            ))}
                                        </TextField>
                                        <TextField
                                            select
                                            id="number"
                                            name="number"
                                            label="Number"
                                            value={formData.number}
                                            onChange={handleChange}
                                            required
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            disabled={!!editingInstance}
                                        >
                                            {[...Array(10).keys()].map(i => (
                                                <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>
                                            ))}
                                        </TextField>
                                        <TextField
                                            select
                                            id="clo"
                                            name="cloId"
                                            label="CLO"
                                            value={formData.cloId}
                                            onChange={handleChange}
                                            fullWidth
                                            required
                                            InputLabelProps={{ shrink: true }}
                                        >
                                            {clos.map(clo => (
                                                <MenuItem key={clo.cloid} value={clo.cloid}>{clo.label}</MenuItem>
                                            ))}
                                        </TextField>
                                        <TextField
                                            label="Total Marks"
                                            type="number"
                                            name="totalMarks"
                                            value={formData.totalMarks}
                                            onChange={handleChange}
                                            inputProps={{ min: 1, max: 100 }}
                                            fullWidth
                                            required
                                            InputLabelProps={{ shrink: true }}
                                            error={formData.totalMarks && !validateMarks(formData.totalMarks)}
                                            helperText={formData.totalMarks && !validateMarks(formData.totalMarks) && 'Total Marks must be between 1 and 100.'}
                                        />
                                        {students.map(student => (
                                            <Box key={student.stuid} display="flex" alignItems="center" gap={2}>
                                                <TextField
                                                    label="Reg. ID"
                                                    InputLabelProps={{ shrink: true }}
                                                    disabled
                                                    value={student.stuid}
                                                    InputProps={{ readOnly: true }}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                                <TextField
                                                    label="Student Name"
                                                    InputLabelProps={{ shrink: true }}
                                                    disabled
                                                    value={student.stuname}
                                                    InputProps={{ readOnly: true }}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                                <TextField
                                                    label="Obtained Marks"
                                                    InputLabelProps={{ shrink: true }}
                                                    type="text"
                                                    variant="outlined"
                                                    value={formData.studentMarks.find(mark => mark.stuid === student.stuid)?.obtainedmarks || ''}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (!isNaN(value) && Number(value) >= 0) {
                                                            handleStudentMarkChange(student.stuid, value);
                                                        }
                                                    }}
                                                    fullWidth
                                                    margin="normal"
                                                    required
                                                    inputProps={{
                                                        pattern: "[0-9]*\\.?[0-9]+",
                                                        title: "Please enter a valid number"
                                                    }}
                                                />

                                            </Box>
                                        ))}
                                        <Button
                                            style={{ backgroundColor: '#6c63ff', color: 'white' }}
                                            type="submit"
                                            variant="contained"
                                            sx={{ mt: 2 }}
                                            disabled={
                                                !formData.assessmentType ||
                                                !formData.number ||
                                                !formData.cloId ||
                                                !formData.totalMarks ||
                                                !validateMarks(formData.totalMarks) ||
                                                formData.studentMarks.some(mark =>
                                                    !mark.obtainedmarks ||
                                                    Number(mark.obtainedmarks) < 0 ||
                                                    Number(mark.obtainedmarks) > Number(formData.totalMarks))
                                            }>
                                            {editingInstance ? 'Update Marks' : 'Submit Marks'}
                                        </Button>
                                        {error && (
                                            <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>
                        )}
                    </>
                )}

                {isReadyToEnterMarks && uniqueAssessmentDetails.length > 0 && (
                    <>
                        <TableContainer component={Paper} elevation={12} sx={{
                            borderRadius: 2,
                            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                            border: '1px solid rgba(0,0,0,0.12)'
                        }}>
                            <Table aria-label="marks entry table">
                                <TableHead sx={{ bgcolor: '#e0e0e0' }}>
                                    <TableRow sx={{ '& th': { fontWeight: 'bold' } }}>
                                        <StickyTableCell align="center" sx={{ bgcolor: '#e0e0e0' }} position="first" style={{ whiteSpace: 'nowrap' }}>Sr. No.</StickyTableCell>
                                        <StickyTableCell align="center" sx={{ bgcolor: '#e0e0e0' }} position="second" style={{ whiteSpace: 'nowrap' }}>Reg. ID</StickyTableCell>
                                        <StickyTableCell align="center" sx={{ bgcolor: '#e0e0e0' }} position="third" style={{ whiteSpace: 'nowrap' }}>Student Name</StickyTableCell>
                                        {uniqueAssessmentDetails.map(assessment => (
                                            <TableCell key={assessment.instancename} align="center" style={{ whiteSpace: 'pre-line' }}>
                                                {assessment.instancename}{'\n'}
                                                {assessment.cloid}{'\n'}
                                                {assessment.totalmarks}(Marks){'\n'}
                                                <IconButton onClick={() => handleEdit(assessment.instancename)} size="small" sx={{ ml: 1 }}>
                                                    <Edit />
                                                </IconButton>{'\n'}
                                                <IconButton onClick={() => { setInstanceToDelete(assessment.instancename); setConfirmDelete(true); }} size="small" sx={{ ml: 1 }}>
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {students.map((student, index) => (
                                        <TableRow key={student.stuid}>
                                            <StickyTableCell align="center" position="first" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{index + 1}</StickyTableCell>
                                            <StickyTableCell align="center" position="second" style={{ borderRight: '1px solid rgba(0,0,0,0.12)', whiteSpace: 'nowrap' }}>{student.stuid}</StickyTableCell>
                                            <StickyTableCell align="center" position="third" style={{ borderRight: '1px solid rgba(0,0,0,0.12)', whiteSpace: 'nowrap' }}>{student.stuname}</StickyTableCell>
                                            {uniqueAssessmentDetails.map(assessment => {
                                                const mark = assessmentDetails.find(detail =>
                                                    detail.stuid === student.stuid &&
                                                    detail.instancename === assessment.instancename
                                                );
                                                return (
                                                    <TableCell key={`${assessment.instancename}-${student.stuid}`} align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>
                                                        {mark ? mark.obtainedmarks : 'N/A'}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: '#6c63ff', color: 'white' }}
                            onClick={markCourseAsComplete}
                            sx={{ my: 2 }}
                        >
                            Mark Course Complete
                        </Button>
                        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                    </>
                )}

                <Dialog
                    open={confirmDelete}
                    onClose={() => setConfirmDelete(false)}
                >
                    <DialogTitle>Confirm Deletion</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this assessment instance? This action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setConfirmDelete(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleDeleteInstance} color="primary">
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </Box>
    );
};

export default MarkEntryPage;
