import React, { useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import {
    Drawer, List, ListItem, ListItemIcon, ListItemText, Box, Typography, Divider, IconButton
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import GroupIcon from '@mui/icons-material/Group';
import BookIcon from '@mui/icons-material/Book';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import TimelineIcon from '@mui/icons-material/Timeline';
import SettingsIcon from '@mui/icons-material/Settings';
import LockReset from '@mui/icons-material/LockReset';
import { useUser } from '../context/UserContext';
import SchoolIcon from "@mui/icons-material/School";

const drawerWidth = 280;
const selectedColor = '#6c63ff';

const NavBar = () => {
    const { user, logout } = useUser();
    const navigate = useNavigate();
    const location = useLocation();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const icons = {
        Home: <HomeIcon />,
        Department: <DashboardIcon />,
        Program: <SchoolIcon />,
        Batch: <AssignmentIndIcon />,
        Teacher: <GroupIcon />,
        Course: <BookIcon />,
        AssignCourse: <AssignmentIcon />,
        TaxonomyDomain: <LayersIcon />,
        CLOPLO: <TimelineIcon />,
        ProgramPLO: <TimelineIcon />,
        CQI: <SettingsIcon />,
        Update: <LockReset />
    };

    const links = {
        'administrator': [
            { path: "/administrator", label: "Home", icon: 'Home' },
            { path: "/administrator/departments", label: "Department", icon: 'Department' },
            { path: "/administrator/programs", label: "Program", icon: 'Program' },
            { path: "/administrator/batches", label: "Batch", icon: 'Batch' },
            { path: "/administrator/teachers", label: "Teacher", icon: 'Teacher' },
            { path: "/administrator/courses", label: "Course", icon: 'Course' },
            { path: "/administrator/assignedcourses", label: "Assign Course", icon: 'AssignCourse' },
            { path: "/administrator/taxonomydomains", label: "Taxonomy Domain", icon: 'TaxonomyDomain' },
            { path: "/administrator/courseattainment", label: "Course Wise CLO and PLO Attainment", icon: 'CLOPLO' },
            { path: "/administrator/programattainment", label: "Program Wise PLO Attainment", icon: 'ProgramPLO' },
            { path: "/administrator/cqisuggestion", label: "CQI Suggestion", icon: 'CQI' },
            { path: "/administrator/updatepassword", label: "Update Password", icon: 'Update' }
        ],
        'teacher': [
            { path: "/teacher", label: "Home", icon: 'Home' },
            { path: "/teacher/assessmentweightages", label: "Set Assessment Weightage", icon: 'Teacher' },
            { path: "/teacher/closentry", label: "Define CLOs", icon: 'CLOPLO' },
            { path: "/teacher/marksentry", label: "Enter Marks", icon: 'Teacher' },
            { path: "/teacher/courseattainment", label: "Course Wise CLO and PLO Attainment", icon: 'CLOPLO' },
            { path: "/teacher/cqisuggestion", label: "CQI Suggestion", icon: 'CQI' },
            { path: "/teacher/updatepassword", label: "Update Password", icon: 'Update' }
        ],
        'student': [
            { path: "/student", label: "Home", icon: 'Home' },
            { path: "/student/courseattainment", label: "Course Wise CLO and PLO Attainment", icon: 'CLOPLO' },
            { path: "/student/programattainment", label: "Program Wise PLO Attainment", icon: 'ProgramPLO' },
            { path: "/student/improvementsuggestion", label: "Improvement Suggestion", icon: 'CQI' },
            { path: "/student/updatepassword", label: "Update Password", icon: 'Update' }
        ]
    }[user.role] || [];

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const drawerContent = (
        <Box sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#1a202c',
        }}>
            <Box sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#1a202c',
                color: '#fff',
            }}>
                <Typography variant="h6">{`${user.role.toUpperCase()} Dashboard`}</Typography>
            </Box>
            <Divider sx={{ bgcolor: 'rgba(255, 255, 255, 0.12)' }} />
            <List>
                {links.map((link, index) => (
                    <ListItem
                        button
                        key={index}
                        component={RouterLink}
                        to={link.path}
                        onClick={() => setMobileOpen(false)}
                        sx={{
                            borderRadius: 2,
                            backgroundColor: location.pathname === link.path ? selectedColor : 'inherit',
                            color: '#ddd',
                            '&:hover': {
                                backgroundColor: selectedColor,
                                color: '#fff',
                            },
                        }}
                    >
                        <ListItemIcon sx={{ color: 'inherit' }}>{icons[link.icon]}</ListItemIcon>
                        <ListItemText primary={link.label} />
                    </ListItem>
                ))}
            </List>
            <Divider sx={{ bgcolor: 'rgba(255, 255, 255, 0.12)' }} />
            <List>
                <ListItem button onClick={handleLogout} sx={{ color: '#ddd' }}>
                    <ListItemIcon sx={{ color: 'inherit' }}><ExitToAppIcon /></ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItem>
            </List>
        </Box>
    );

    return (
        <>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ display: { md: 'none' } }}
            >
                <MenuIcon />
            </IconButton>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', md: 'block' },
                    width: drawerWidth,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        backgroundColor: '#1a202c',
                    },
                }}
                open
            >
                {drawerContent}
            </Drawer>
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        backgroundColor: '#1a202c',
                    },
                }}
            >
                {drawerContent}
            </Drawer>
        </>
    );
};

export default NavBar;
