import React, { useState, useEffect } from 'react';
import {
  Container, Typography, Button, TextField, Box, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, Card, CardContent, MenuItem,
  Collapse, IconButton, useMediaQuery, useTheme, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle
} from '@mui/material';
import { Add, Close, Edit, Delete, ExitToApp } from '@mui/icons-material';
import batchService from '../api/batchService';
import programService from '../api/programService';

const BatchPage = () => {
  const [batches, setBatches] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [programMap, setProgramMap] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [formError, setFormError] = useState('');
  const [open, setOpen] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmMarkLeft, setConfirmMarkLeft] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('');
  const [batchToDelete, setBatchToDelete] = useState(null);
  const [studentToMarkLeft, setStudentToMarkLeft] = useState(null);
  const [formData, setFormData] = useState({
    batchid: '',
    batchsession: '',
    batchyear: '',
    batchsection: '',
    progid: '',
    studentCount: '',
    students: []
  });

  useEffect(() => {
    fetchBatches();
    fetchPrograms();
  }, []);

  const fetchBatches = async () => {
    try {
      const [batchesResponse, programsResponse] = await Promise.all([
        batchService.getBatches(),
        programService.getPrograms()
      ]);
      const programMap = programsResponse.data.reduce((acc, program) => {
        acc[program.progid] = program.progname;
        return acc;
      }, {});

      setProgramMap(programMap);

      const sortedBatches = batchesResponse.data.sort((a, b) => {
        const progNameA = programMap[a.progid];
        const progNameB = programMap[b.progid];
        if (progNameA < progNameB) return -1;
        if (progNameA > progNameB) return 1;
        return 0;
      });

      setBatches(sortedBatches);
    } catch (error) {
      console.error('Failed to fetch batches or programs:', error);
    }
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const fetchPrograms = async () => {
    try {
      const response = await programService.getPrograms();
      setPrograms(response.data);

      const map = response.data.reduce((acc, program) => {
        acc[program.progid] = program.progname;
        return acc;
      }, {});
      setProgramMap(map);
    } catch (error) {
      console.error('Failed to fetch programs:', error);
    }
  };

  const handleChange = (e) => {
    if (formError) {
      setFormError('');
    }

    const { name, value } = e.target;

    setFormData(prev => {
      const newFormData = { ...prev, [name]: value };

      if (['batchsession', 'batchyear', 'batchsection', 'progid', 'studentCount'].includes(name)) {
        const studentCount = parseInt(newFormData.studentCount) || 0;
        const students = Array.from({ length: studentCount }, (_, index) => ({
          stuname: prev.students[index] ? prev.students[index].stuname : '',
          stucnic: prev.students[index] ? prev.students[index].stucnic : '',
          stuemail: prev.students[index] ? prev.students[index].stuemail : ''
        }));
        newFormData.students = students;
      }

      return newFormData;
    });
  };

  const handleStudentChange = (index, field, value) => {
    if (formError) {
      setFormError('');
    }

    const updatedStudents = formData.students.map((student, i) => {
      if (i === index) return { ...student, [field]: value };
      return student;
    });

    setFormData({ ...formData, students: updatedStudents });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.students.length === 0) {
      setFormError('At least one student must be added.');
      return;
    }

    const formattedStudents = formData.students.map(student => ({
      ...student,
      stuname: formatStudentName(student.stuname),
      stucnic: student.stucnic
    }));

    try {
      if (formData.batchid) {
        await batchService.updateBatch(formData.batchid, { students: formattedStudents });
      } else {
        const batchData = {
          ...formData,
          progname: programMap[formData.progid],
          students: formattedStudents
        };
        await batchService.createBatch(batchData);
      }
      setFormData({
        batchid: '', batchsession: '', batchyear: '', batchsection: '', progid: '', studentCount: '', students: []
      });
      setFormError('');
      setShowForm(false);
      fetchBatches();
    } catch (error) {
      setFormError(error.response.data || 'Failed to create or update batch');
    }
  };

  const handleEdit = async (batch) => {
    if (formError) {
      setFormError('');
    }
    setFormData({
      batchid: batch.batchid,
      batchsession: batch.batchsession,
      batchyear: batch.batchyear,
      batchsection: batch.batchsection,
      progid: batch.progid,
      studentCount: batch.students.length,
      students: batch.students.map(student => ({
        stuid: student.stuid,
        stuname: student.stuname,
        stucnic: student.stucnic,
        stuemail: student.stuemail
      }))
    });
    setShowForm(true);
  };

  const handleDeleteClick = async (batch) => {
    try {
      const response = await batchService.isBatchInUse(batch.batchid);
      const isInUse = response.data.inUse;
      if (isInUse) {
        setDialogTitle('Action Not Allowed');
        setConfirmMessage('Batch is in use by an assigned course or students enrolled in the course and cannot be deleted.');
        setConfirmDelete(true);
        return;
      }
    } catch (error) {
      console.error('Failed to check if batch is in use:', error);
    }

    setBatchToDelete(batch);
    setDialogTitle('Confirm Deletion');
    setConfirmMessage('Are you sure you want to delete this batch? This action cannot be undone.');
    setConfirmDelete(true);
  };

  const handleDelete = async () => {
    try {
      await batchService.deleteBatch(batchToDelete.batchid);
      setConfirmDelete(false);
      setBatchToDelete(null);
      fetchBatches();
    } catch (error) {
      setConfirmMessage(error.response.data || 'Failed to delete the batch.');
    }
  };

  const handleMarkLeftClick = (student) => {
    setStudentToMarkLeft(student);
    setDialogTitle('Confirm Mark as Left');
    setConfirmMessage('Are you sure you want to mark this student as left? This action cannot be undone.');
    setConfirmMarkLeft(true);
  };

  const handleMarkLeft = async () => {
    try {
      await batchService.markStudentLeft(studentToMarkLeft.stuid);
      setConfirmMarkLeft(false);
      setStudentToMarkLeft(null);
      fetchBatches();
    } catch (error) {
      console.error('Failed to mark the student as left:', error);
      setConfirmMessage(error.response.data || 'Failed to mark the student as left.');
    }
  };

  const toggleForm = () => {
    setShowForm(!showForm);

    if (!showForm) {
      setFormData({
        batchid: '', batchsession: '', batchyear: '', batchsection: '', progid: '', studentCount: '', students: []
      });

      setFormError('');
    }
  };

  const toggleOpen = (id) => {
    setOpen(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
    return emailRegex.test(email);
  };

  const validateCNIC = (CNIC) => {
    return CNIC >= 1000000000000 && CNIC <= 9999999999999;
  };

  const validateName = (name) => {
    const formattedName = formatStudentName(name);
    const NameRegex = /^[A-Z]+(?:[ ][A-Z]+)*$/;
    return NameRegex.test(formattedName);
  };

  const allFieldsFilled = formData.batchsession && formData.batchyear && formData.batchsection && formData.progid && formData.studentCount && formData.students.every(student => student.stuname && student.stucnic && student.stuemail && /^[a-zA-Z0-9._%+-]+@gmail\.com$/.test(student.stuemail) && validateCNIC(student.stucnic) && validateName(student.stuname));

  return (
    <Box display="flex" justifyContent="center" sx={{ mt: 4, mb: 4, ml: matches ? '280px' : 0, width: matches ? `calc(100% - 280px)` : '100%' }}>
      <Container maxWidth="lg">
        <Typography variant="h4" sx={{ mb: 3 }}>Batches</Typography>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button
            startIcon={showForm ? <Close /> : <Add />}
            variant="contained"
            style={{ backgroundColor: '#6c63ff', color: 'white' }}
            onClick={toggleForm}
            sx={{ borderRadius: 2, boxShadow: '0 3px 5px 2px rgba(105, 140, 255, .3)' }}
          >
            {showForm ? 'Cancel' : 'Add Batch'}
          </Button>
        </Box>
        {showForm && (
          <Card elevation={12} sx={{ mb: 3, p: 2, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>{formData.batchid ? 'Edit Batch' : 'Add New Batch'}</Typography>
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField select name="batchsession" label="Session" value={formData.batchsession} onChange={handleChange} required fullWidth InputLabelProps={{ shrink: true }} disabled={!!formData.batchid}>
                  {['FALL', 'SPRING'].map((session) => (
                    <MenuItem key={session} value={session}>{session}</MenuItem>
                  ))}
                </TextField>
                <TextField select name="batchyear" label="Year" value={formData.batchyear} onChange={handleChange} required fullWidth InputLabelProps={{ shrink: true }} disabled={!!formData.batchid}>
                  {Array.from(new Array(20), (_, i) => new Date().getFullYear() - i).map(year => (
                    <MenuItem key={year} value={year}>{year}</MenuItem>
                  ))}
                </TextField>
                <TextField select name="batchsection" label="Section" value={formData.batchsection} onChange={handleChange} required fullWidth InputLabelProps={{ shrink: true }} disabled={!!formData.batchid}>
                  {Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i)).map(section => (
                    <MenuItem key={section} value={section}>{section}</MenuItem>
                  ))}
                </TextField>
                <TextField select name="progid" label="Program" value={formData.progid} onChange={handleChange} required fullWidth InputLabelProps={{ shrink: true }} disabled={!!formData.batchid}>
                  {programs.map((program) => (
                    <MenuItem key={program.progid} value={program.progid}>{program.progname}</MenuItem>
                  ))}
                </TextField>
                <TextField name="studentCount" label="Number of Students" type="number" value={formData.studentCount} onChange={handleChange} required fullWidth InputProps={{ inputProps: { min: 0, max: 70 } }} InputLabelProps={{ shrink: true }} disabled={!!formData.batchid} />
                {formData.students.map((student, index) => (
                  <Box key={index} sx={{ display: 'flex', gap: 2 }}>
                    <TextField name={`stuname-${index}`} label="Student Name" value={student.stuname}
                      onChange={(e) => handleStudentChange(index, 'stuname', e.target.value)} required
                      fullWidth InputLabelProps={{ shrink: true }}
                      error={student.stuname && !validateName(student.stuname)}
                      helperText={student.stuname && !validateName(student.stuname) && 'Only Use English Alphabets & Spaces'}
                    />
                    <TextField name={`stucnic-${index}`} label="CNIC" type="number" value={student.stucnic}
                      onChange={(e) => handleStudentChange(index, 'stucnic', e.target.value)} required fullWidth
                      InputProps={{ inputProps: { min: 1000000000000, max: 9999999999999 } }}
                      InputLabelProps={{ shrink: true }}
                      error={student.stucnic && !validateCNIC(student.stucnic)}
                      helperText={student.stucnic && !validateCNIC(student.stucnic) && 'Please enter a valid CNIC'}
                    />
                    <TextField
                      name={`stuemail-${index}`}
                      label="Email"
                      type="email"
                      value={student.stuemail || ''}
                      onChange={(e) => handleStudentChange(index, 'stuemail', e.target.value)}
                      required
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      error={student.stuemail && !validateEmail(student.stuemail)}
                      helperText={student.stuemail && !validateEmail(student.stuemail) && 'Please enter a valid Gmail address'}
                    />
                  </Box>
                ))}
                <Button
                  style={{ backgroundColor: '#6c63ff', color: 'white' }}
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2 }}
                  disabled={!allFieldsFilled}
                >
                  {formData.batchid ? 'Update Batch' : 'Create Batch'}
                </Button>

                {formError && (
                  <Typography color="error" sx={{ mt: 2 }}>{formError}</Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        )}
        <TableContainer component={Paper} elevation={12} sx={{
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          border: '1px solid rgba(0,0,0,0.12)'
        }}>
          <Table aria-label="batches table">
            <TableHead sx={{ bgcolor: '#e0e0e0' }}>
              <TableRow sx={{ '& th': { fontWeight: 'bold' } }}>
                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Sr. No.</TableCell>
                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Batch ID</TableCell>
                <TableCell align="center">Session</TableCell>
                <TableCell align="center">Year</TableCell>
                <TableCell align="center">Section</TableCell>
                <TableCell align="center">Program</TableCell>
                <TableCell align="center">Students</TableCell>
                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {batches.map((batch, index) => (
                <React.Fragment key={batch.batchid}>
                  <TableRow>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{index + 1}</TableCell>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)', whiteSpace: 'nowrap' }}>{batch.batchid}</TableCell>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{batch.batchsession}</TableCell>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{batch.batchyear}</TableCell>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{batch.batchsection}</TableCell>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)', whiteSpace: 'nowrap' }}>{programMap[batch.progid]}</TableCell>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>
                      <Button onClick={() => toggleOpen(batch.batchid)}>
                        {open[batch.batchid] ? 'Hide Students' : 'View Students'}
                      </Button>
                    </TableCell>
                    <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                      <IconButton color="primary" onClick={() => handleEdit(batch)}>
                        <Edit />
                      </IconButton>
                      <IconButton color="secondary" onClick={() => handleDeleteClick(batch)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                      <Collapse in={open[batch.batchid]} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            Students
                          </Typography>
                          <Table size="small" aria-label="students">
                            <TableHead>
                              <TableRow sx={{ '& th': { fontWeight: 'bold' } }}>
                                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Student ID</TableCell>
                                <TableCell align="center">Name</TableCell>
                                <TableCell align="center">CNIC</TableCell>
                                <TableCell align="center">Email</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {batch.students.map((student) => (
                                <TableRow key={student.stuid}>
                                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)', whiteSpace: 'nowrap' }}>{student.stuid}</TableCell>
                                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)', whiteSpace: 'nowrap' }}>{student.stuname}</TableCell>
                                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{student.stucnic}</TableCell>
                                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{student.stuemail}</TableCell>
                                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{student.stuleftstatus ? 'Left' : 'Active'}</TableCell>
                                  <TableCell align="center">
                                    {!student.stuleftstatus && (
                                      <IconButton color="warning" onClick={() => handleMarkLeftClick(student)}>
                                        <ExitToApp />
                                      </IconButton>
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog
          open={confirmDelete}
          onClose={() => setConfirmDelete(false)}
        >
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {confirmMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDelete(false)} color="primary">
              Cancel
            </Button>
            {!confirmMessage.includes('in use') && (
              <Button onClick={handleDelete} color="primary">
                Confirm
              </Button>
            )}
          </DialogActions>
        </Dialog>
        <Dialog
          open={confirmMarkLeft}
          onClose={() => setConfirmMarkLeft(false)}
        >
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {confirmMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmMarkLeft(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleMarkLeft} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

function formatStudentName(name) {
  let formattedName = name.trim();
  formattedName = name.replace(/\s+/g, ' ');
  return formattedName.toUpperCase();
}

export default BatchPage;
