import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  Typography,
  CircularProgress,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  BarChart,
  Cell,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { blue, green, orange, red } from "@mui/material/colors";
import SchoolIcon from "@mui/icons-material/School";
import GroupIcon from "@mui/icons-material/Group";
import BookIcon from "@mui/icons-material/Book";
import DashboardIcon from '@mui/icons-material/Dashboard';
import homeService from "../api/homeService";

const COLORS = [blue[700], green[700], orange[700], red[700]];

function AdminHomePage() {
  const [stats, setStats] = useState({
    departments: 0,
    programs: 0,
    courses: 0,
    teachers: 0,
  });
  const [loading, setLoading] = useState(true);
  const [courseData, setCourseData] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [courses, setCourses] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState("");
  const [batches, setBatches] = useState([]);

  useEffect(() => {
    async function fetchStats() {
      try {
        const response = await homeService.getAdminStats();
        setStats(response.data);

        const courseResponse = await homeService.getCourseAttainmentStats();
        const transformedCourseData = transformData(courseResponse.data);
        setCourseData(transformedCourseData);
        setCourses(
          [
            ...new Set(courseResponse.data.map((item) => item.assignedcrsid)),
          ].sort()
        );
        setSelectedCourse(courseResponse.data[0]?.assignedcrsid || "");

        const programResponse = await homeService.getProgramAttainmentStats();
        setProgramData(transformData(programResponse.data));
        setBatches(
          [...new Set(programResponse.data.map((item) => item.batchid))].sort()
        );
        setSelectedBatch(programResponse.data[0]?.batchid || "");
      } catch (error) {
        console.error("Failed to fetch statistics", error);
      } finally {
        setLoading(false);
      }
    }
    fetchStats();
  }, []);

  const transformData = (data) => {
    const dataMap = {};
    data.forEach((item) => {
      const key = item.batchid || item.assignedcrsid; // Handle both course and batch keys
      if (!dataMap[key]) {
        dataMap[key] = { CLO: [], PLO: [] };
      }
      const entry = {
        id: item.id,
        average_attainment: parseFloat(item.average_attainment),
        count_pass: parseInt(item.count_pass, 10),
        count_fail: parseInt(item.count_fail, 10),
      };
      if (item.type === "CLO") {
        dataMap[key].CLO.push(entry);
      } else {
        dataMap[key].PLO.push(entry);
      }
    });
    return Object.keys(dataMap)
      .sort()
      .reduce((acc, key) => {
        acc[key] = dataMap[key];
        return acc;
      }, {});
  };

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
  };

  const handleBatchChange = (event) => {
    setSelectedBatch(event.target.value);
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  function EnhancedBarChart({ data, dataKey, colors }) {
    const maxBarWidth = 60;
    const barSize = Math.min(maxBarWidth, 300 / data.length);

    const gradientColors = [
      { start: theme.palette.primary.light, end: theme.palette.primary.dark },
      {
        start: theme.palette.secondary.light,
        end: theme.palette.secondary.dark,
      },
    ];

    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <defs>
            {gradientColors.map((color, index) => (
              <linearGradient
                id={`colorUv${index}`}
                key={index}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="10%" stopColor={color.start} stopOpacity={0.8} />
                <stop offset="90%" stopColor={color.end} stopOpacity={1} />
              </linearGradient>
            ))}
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="id" tick={{ fill: theme.palette.text.primary }} />
          <YAxis tick={{ fill: theme.palette.text.primary }} />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey={dataKey} barSize={barSize} radius={[10, 10, 0, 0]}>
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={`url(#colorUv${index % gradientColors.length})`}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{
        mt: 4,
        mb: 4,
        ml: matches ? "300px" : "20px",
        mr: matches ? "20px" : "20px",
        width: matches ? `calc(100% - 320px)` : "calc(100% - 40px)",
      }}
    >
      <Grid container spacing={3}>
        {Object.keys(stats).map((key, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Grid item xs={12} sm={6} md={3} key={index}>
              <StatCard
                title={key.charAt(0).toUpperCase() + key.slice(1)}
                value={stats[key]}
                icon={getIcon(key)}
                colorIndex={index}
              />
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="course-selector-label">Select a Course</InputLabel>
            <Select
              labelId="course-selector-label"
              id="course-selector"
              value={selectedCourse}
              label="Select a Course"
              onChange={handleCourseChange}
              style={{ marginBottom: 20 }}
            >
              {courses.map((course) => (
                <MenuItem key={course} value={course}>
                  {course}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">CLO Attainment Overview</Typography>
          <EnhancedBarChart
            data={courseData[selectedCourse]?.CLO || []}
            dataKey="average_attainment"
            colors={COLORS}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">PLO Attainment Overview</Typography>
          <EnhancedBarChart
            data={courseData[selectedCourse]?.PLO || []}
            dataKey="average_attainment"
            colors={COLORS}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="batch-selector-label">Select a Batch</InputLabel>
            <Select
              labelId="batch-selector-label"
              id="batch-selector"
              value={selectedBatch}
              label="Select a Batch"
              onChange={handleBatchChange}
              style={{ marginBottom: 20 }}
            >
              {batches.map((batch) => (
                <MenuItem key={batch} value={batch}>
                  {batch}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6">
            Program-Wise PLO Attainment Overview
          </Typography>
          <EnhancedBarChart
            data={programData[selectedBatch]?.PLO || []}
            dataKey="average_attainment"
            colors={COLORS}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

function getIcon(key) {
  switch (key) {
    case "departments":
      return <DashboardIcon />;
    case "programs":
      return <SchoolIcon />;
    case "courses":
      return <BookIcon />;
    case "teachers":
      return <GroupIcon />;
    default:
      return <SchoolIcon />;
  }
}

function StatCard({ title, value, icon, colorIndex }) {
  const theme = useTheme();

  const gradientColors = [
    { start: theme.palette.primary.light, end: theme.palette.primary.dark },
    { start: theme.palette.secondary.light, end: theme.palette.secondary.dark },
  ];

  const background = `linear-gradient(145deg, ${
    gradientColors[colorIndex % gradientColors.length].start
  }, ${gradientColors[colorIndex % gradientColors.length].end})`;

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: 2,
        height: "auto",
        minWidth: "350px",
        background: background,
        color: "#fff",
        boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
        "&:hover": {
          boxShadow: "0 6px 24px 0 rgba(0,0,0,0.18)",
          transform: "translateY(-5px)",
          transition: "all 0.3s ease-out",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 1,
        }}
      >
        <Typography variant="h6" component="div" sx={{ fontWeight: "medium" }}>
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "2.5rem",
            animation: "float 3s ease-in-out infinite",
          }}
        >
          {icon}
          <Typography
            variant="h4"
            component="div"
            sx={{ fontWeight: "bold", marginLeft: 2 }}
          >
            {value}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

function CustomTooltip({ active, payload, label }) {
  const theme = useTheme();
  if (active && payload && payload.length) {
    return (
      <Card
        sx={{
          p: 2,
          backgroundColor: "rgba(255,255,255,0.9)",
          boxShadow: theme.shadows[3],
        }}
      >
        <Typography variant="subtitle1" color="text.primary">
          {label}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {`Average Attainment: ${payload[0].value.toFixed(2)}%`}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {`Pass: ${payload[0].payload.count_pass}, Fail: ${payload[0].payload.count_fail}`}
        </Typography>
      </Card>
    );
  }

  return null;
}

export default AdminHomePage;
