import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const createAssessmentInstance = (assessmentData) => {
    return axios.post(`${baseURL}/marksentry`, assessmentData);
};

const getCourseAssessmentDetails = (assignedcrsid) => {
    return axios.get(`${baseURL}/marksentry/${assignedcrsid}`);
};

const updateAssessmentInstance = (assessmentData) => {
    return axios.patch(`${baseURL}/marksentry/update`, assessmentData);
};

const deleteAssessmentInstance = (data) => {
    return axios.delete(`${baseURL}/marksentry/delete/${data.instancename}/${data.assignedcrsid}`);
};

const areWeightagesDefined = (assignedcrsid) => {
    return axios.get(`${baseURL}/marksentry/assessmentweightage/${assignedcrsid}`);
};

const areCLOsDefine = (assignedcrsid) => {
    return axios.get(`${baseURL}/marksentry/clo/${assignedcrsid}`);
};

const getCLOsByAssignedCrsId = (assignedcrsid) => {
    return axios.get(`${baseURL}/marksentry/clos/assignedcourse/${assignedcrsid}`);
};

const markCourseCompleted = (assignedcrsid) => {
    return axios.patch(`${baseURL}/marksentry/assignedcourse/${assignedcrsid}`);
};

const markEntryService = {
    createAssessmentInstance,
    getCourseAssessmentDetails,
    updateAssessmentInstance,
    deleteAssessmentInstance,
    areWeightagesDefined,
    areCLOsDefine,
    getCLOsByAssignedCrsId,
    markCourseCompleted
};

export default markEntryService;
