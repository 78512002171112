import React, { useState, useEffect } from 'react';
import {
  Container, Typography, Button, TextField, Box, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, Card, CardContent, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  useMediaQuery, useTheme
} from '@mui/material';
import { Add, Close, Edit, Delete } from '@mui/icons-material';
import courseService from '../api/courseService';

const CoursePage = () => {
  const [courses, setCourses] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [formError, setFormError] = useState('');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('');
  const [courseToDelete, setCourseToDelete] = useState(null);
  const [formData, setFormData] = useState({
    crscode: '',
    oldCrsCode: '',
    crsname: '',
    crsch: ''
  });

  useEffect(() => {
    fetchCourses();
  }, []);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const fetchCourses = async () => {
    try {
      const response = await courseService.getCourses();
      setCourses(response.data);
    } catch (error) {
      console.error('Failed to fetch courses:', error);
    }
  };

  const handleChange = (e) => {
    if (formError) {
      setFormError('');
    }
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name === 'crscode' ? value.trim().replace(/\s/g, '').toUpperCase() : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formattedCrsName = formatName(formData.crsname);
    const data = {
      ...formData,
      crsname: formattedCrsName,
      oldCrsCode: formData.oldCrsCode || formData.crscode
    };

    try {
      if (formData.oldCrsCode) {
        await courseService.updateCourse(data.oldCrsCode, data);
      } else {
        await courseService.createCourse(data);
      }
      resetForm();
      fetchCourses();
    } catch (error) {
      setFormError(error.response.data || 'Failed to update course');
    }
  };

  const handleEdit = async (course) => {
    try {
      const response = await courseService.isCourseInUse(course.crscode);
      const isInUse = response.data.inUse;
      if (isInUse) {
        setDialogTitle('Action Not Allowed');
        setConfirmMessage('Course is in use and cannot be edited.');
        setConfirmDelete(true);
        return;
      }
    } catch (error) {
      console.error('Failed to check if course is in use:', error);
    }

    setFormData({
      crscode: course.crscode,
      oldCrsCode: course.crscode,
      crsname: course.crsname,
      crsch: course.crsch
    });
    setShowForm(true);
  };

  const handleDeleteClick = async (course) => {
    try {
      const response = await courseService.isCourseInUse(course.crscode);
      const isInUse = response.data.inUse;
      if (isInUse) {
        setDialogTitle('Action Not Allowed');
        setConfirmMessage('Course is in use and cannot be deleted.');
        setConfirmDelete(true);
        return;
      }
    } catch (error) {
      console.error('Failed to check if course is in use:', error);
    }

    setCourseToDelete(course);
    setDialogTitle('Confirm Deletion');
    setConfirmMessage('Are you sure you want to delete this course? This action cannot be undone.');
    setConfirmDelete(true);
  };

  const handleDelete = async () => {
    try {
      await courseService.deleteCourse(courseToDelete.crscode);
      resetDialog();
      fetchCourses();
    } catch (error) {
      setConfirmMessage(error.response.data || 'Failed to delete the course.');
    }
  };

  const resetForm = () => {
    setShowForm(false);
    setFormData({ crscode: '', crsname: '', crsch: '', oldCrsCode: '' });
    setFormError('');
  };

  const resetDialog = () => {
    setConfirmDelete(false);
    setCourseToDelete(null);
    setDialogTitle('');
    setConfirmMessage('');
  };

  const toggleForm = () => {
    resetForm();
    setShowForm(!showForm);
  };

  const validateCH = (CH) => {
    return CH >= 1 && CH <= 10;
  };

  const validateCC = (cc) => {
    const CCRegex = /^[A-Z]+\d+$/;
    return CCRegex.test(cc);
  };

  const validateName = (name) => {
    const formattedName = formatName(name);
    const NameRegex = /^[A-Z]+(?:[ ][A-Z]+)*$/;
    return NameRegex.test(formattedName);
  };

  const allFieldsFilled = formData.crscode && formData.crsname && formData.crsch && validateCH(formData.crsch) && validateName(formData.crsname) && validateCC(formData.crscode);

  return (
    <Box display="flex" justifyContent="center" sx={{ mt: 4, mb: 4, ml: matches ? '280px' : 0, width: matches ? `calc(100% - 280px)` : '100%' }}>
      <Container maxWidth="lg">
        <Typography variant="h4" sx={{ mb: 3 }}>Courses</Typography>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button
            startIcon={showForm ? <Close /> : <Add />}
            variant="contained"
            style={{ backgroundColor: '#6c63ff', color: 'white' }}
            onClick={toggleForm}
            sx={{ borderRadius: 2, boxShadow: '0 3px 5px 2px rgba(105, 140, 255, .3)' }}
          >
            {showForm ? 'Cancel' : 'Add Course'}
          </Button>
        </Box>
        {showForm && (
          <Card elevation={12} sx={{ mb: 3, p: 2, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>{formData.oldCrsCode ? 'Edit Course' : 'Add New Course'}</Typography>
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                  name="crscode"
                  label="Course Code"
                  value={formData.crscode}
                  onChange={handleChange}
                  required
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  error={formData.crscode && !validateCC(formData.crscode)}
                  helperText={formData.crscode && !validateCC(formData.crscode) && 'Only Use English Alphabets Followed by Digits'}
                />
                <TextField
                  name="crsname"
                  label="Name"
                  value={formData.crsname}
                  onChange={handleChange}
                  required
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  error={formData.crsname && !validateName(formData.crsname)}
                  helperText={formData.crsname && !validateName(formData.crsname) && 'Only Use English Alphabets & Spaces'}
                />
                <TextField
                  name="crsch"
                  label="Credit Hours"
                  type="number"
                  value={formData.crsch}
                  onChange={handleChange}
                  required
                  fullWidth
                  inputProps={{ min: 1, max: 10 }}
                  InputLabelProps={{ shrink: true }}
                  error={formData.crsch && !validateCH(formData.crsch)}
                  helperText={formData.crsch && !validateCH(formData.crsch) && 'Credit Hours must be between 1 and 10'}
                />
                <Button type="submit" variant="contained" disabled={!allFieldsFilled} sx={{ mt: 2 }} style={{ backgroundColor: '#6c63ff', color: 'white' }}>
                  {formData.oldCrsCode ? 'Update Course' : 'Create Course'}
                </Button>
                {formError && (
                  <Typography color="error" sx={{ mt: 2 }}>{formError}</Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        )}

        <TableContainer component={Paper} elevation={12} sx={{
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          border: '1px solid rgba(0,0,0,0.12)'
        }}>
          <Table aria-label="courses table">
            <TableHead sx={{ bgcolor: '#e0e0e0' }}>
              <TableRow sx={{ '& th': { fontWeight: 'bold' } }}>
                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Sr. No.</TableCell>
                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Course Code</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Credit Hours</TableCell>
                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courses.map((course, index) => (
                <TableRow key={course.crscode}>
                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{index + 1}</TableCell>
                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{course.crscode}</TableCell>
                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)', whiteSpace: 'nowrap' }}>{course.crsname}</TableCell>
                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{course.crsch}</TableCell>
                  <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                    <IconButton color="primary" onClick={() => handleEdit(course)}>
                      <Edit />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleDeleteClick(course)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog
          open={confirmDelete}
          onClose={() => setConfirmDelete(false)}
        >
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {confirmMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDelete(false)} color="primary">
              Cancel
            </Button>
            {!confirmMessage.includes('in use') && (
              <Button onClick={handleDelete} color="primary">
                Confirm
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

function formatName(name) {
  let formattedName = name.trim().replace(/\s+/g, ' ');
  return formattedName.toUpperCase();
}

export default CoursePage;
