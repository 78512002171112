import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const assignCourse = (data) => {
    return axios.post(`${baseURL}/assignedcourses`, data);
};

const getAssignedCourses = () => {
    return axios.get(`${baseURL}/assignedcourses`);
};

const getBatchStudents = (batchid) => {
    return axios.get(`${baseURL}/assignedcourses/batch/students/${batchid}`);
};

const unenrollStudent = (assignedcrsid, stuid) => {
    return axios.delete(`${baseURL}/assignedcourses/student/unenroll/${assignedcrsid}/${stuid}`);
};

const markAttendanceShort = (assignedcrsid, stuid) => {
    return axios.post(`${baseURL}/assignedcourses/student/attendshort/${assignedcrsid}/${stuid}`);
};

const updateAssignedCourse = async (assignedcrsid, courseData) => {
    return axios.put(`${baseURL}/assignedcourses/update/${assignedcrsid}`, courseData);
};

const deleteAssignedCourse = async (assignedcrsid) => {
    return axios.delete(`${baseURL}/assignedcourses/delete/${assignedcrsid}`);
};

const getCurrentTeachers = () => {
    return axios.get(`${baseURL}/assignedcourses/teachers`);
};

const assignCourseAPI = {
    assignCourse,
    getAssignedCourses,
    getBatchStudents,
    unenrollStudent,
    markAttendanceShort,
    updateAssignedCourse,
    deleteAssignedCourse,
    getCurrentTeachers
};

export default assignCourseAPI;
