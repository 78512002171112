import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const createCourse = (courseData) => {
    return axios.post(`${baseURL}/courses`, courseData);
};

const getCourses = () => {
    return axios.get(`${baseURL}/courses`);
};

const updateCourse = (crscode, data) => {
    return axios.put(`${baseURL}/courses/update/${crscode}`, data);
  };
  
  const deleteCourse = (crscode) => {
    return axios.delete(`${baseURL}/courses/delete/${crscode}`);
  };
  
  const isCourseInUse = (crscode) => {
    return axios.get(`${baseURL}/courses/inuse/${crscode}`);
  };

const courseAPI = {
    createCourse,
    getCourses,
    isCourseInUse,
    updateCourse,
    deleteCourse
};

export default courseAPI;
