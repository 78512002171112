import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const createBatch = (batchData) => {
    return axios.post(`${baseURL}/batches`, batchData);
};

const getBatches = () => {
    return axios.get(`${baseURL}/batches`);
};

const markStudentLeft = (stuid) => {
    return axios.patch(`${baseURL}/batches/student/markleft/${stuid}`);
};

const isBatchInUse = (batchid) => {
    return axios.get(`${baseURL}/batches/useby/${batchid}`);
};

const updateBatch = (batchid, data) => {
    return axios.put(`${baseURL}/batches/update/${batchid}`, data);
};

const deleteBatch = (batchid) => {
    return axios.delete(`${baseURL}/batches/delete/${batchid}`);
};

const batchAPI = {
    createBatch,
    getBatches,
    markStudentLeft,
    isBatchInUse,
    updateBatch,
    deleteBatch
};

export default batchAPI;
