import axios from 'axios';

const baseURL = 'http://localhost:3001/api';

const createDepartment = (deptData) => {
    return axios.post(`${baseURL}/departments`, deptData);
};

const getDepartments = () => {
    return axios.get(`${baseURL}/departments`);
};

const updateDepartment = (deptid, data) => {
    return axios.put(`${baseURL}/departments/update/${deptid}`, data);
};

const deleteDepartment = (deptid) => {
    return axios.delete(`${baseURL}/departments/delete/${deptid}`);
};

const isDepartmentInUse = (deptid) => {
    return axios.get(`${baseURL}/departments/useby/${deptid}`);
};

const departmentAPI = {
    createDepartment,
    getDepartments,
    updateDepartment,
    deleteDepartment,
    isDepartmentInUse
};

export default departmentAPI;
