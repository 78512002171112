import React, { useState, useEffect } from 'react';
import {
  Container, Typography, Button, TextField, Box, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, Card, CardContent, MenuItem, useMediaQuery, useTheme, IconButton, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle
} from '@mui/material';
import { Add, Close, Edit, Delete, ExitToApp } from '@mui/icons-material';
import teacherService from '../api/teacherService';
import programService from '../api/programService';

const TeacherPage = () => {
  const [teachers, setTeachers] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmMarkLeft, setConfirmMarkLeft] = useState(false);
  const [teacherToDelete, setTeacherToDelete] = useState(null);
  const [teacherToMarkLeft, setTeacherToMarkLeft] = useState(null);
  const [dialogTitle, setDialogTitle] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('');
  const [formError, setFormError] = useState('');
  const [formData, setFormData] = useState({
    teachname: '',
    teachcnic: '',
    teachemail: '',
    progid: ''
  });

  useEffect(() => {
    fetchProgramsAndTeachers();
  }, []);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const fetchProgramsAndTeachers = async () => {
    try {
      const programResponse = await programService.getPrograms();
      const teacherResponse = await teacherService.getTeachers();
      const programsData = programResponse.data;
      const teachersData = teacherResponse.data.map(teacher => ({
        ...teacher,
        progname: programsData.find(p => p.progid === teacher.progid)?.progname || 'Unknown'
      })).sort((a, b) => a.progname.localeCompare(b.progname));

      setPrograms(programsData);
      setTeachers(teachersData);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  const handleChange = (e) => {
    if (formError) {
      setFormError('');
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formattedTeachName = formatName(formData.teachname);
    const updatedFormData = {
      ...formData,
      teachname: formattedTeachName,
    };

    if (!/^[a-zA-Z0-9._%+-]+@gmail\.com$/.test(formData.teachemail)) {
      setFormError("Please enter a valid Gmail address");
      return;
    }

    try {
      if (formData.teachid) {
        // Update existing teacher
        await teacherService.updateTeacher(formData.teachid, updatedFormData);
        setConfirmMessage('Teacher updated successfully');
      } else {
        // Add new teacher
        const { teachid, teachpassword } = await teacherService.addTeacher(updatedFormData);
        setTeachers(prev => [...prev, { teachid, teachname: formattedTeachName, teachcnic: formData.teachcnic, teachemail: formData.teachemail, teachpassword, progid: formData.progid, progname: programs.find(p => p.progid === formData.progid).progname }]);
      }
      setFormData({ teachname: '', teachcnic: '', teachemail: '', progid: '' });
      setFormError('');
      setShowForm(false);
      setShowEditForm(false);
      fetchProgramsAndTeachers();
    } catch (error) {
      setFormError(error.response.data || 'Failed to add/update teacher');
    }
  };

  const handleEdit = (teacher) => {
    setFormData({
      teachid: teacher.teachid,
      teachname: teacher.teachname,
      teachcnic: teacher.teachcnic,
      teachemail: teacher.teachemail,
      progid: teacher.progid
    });
    setShowForm(true); // Make sure to open the form
    setShowEditForm(true); // Indicate that this is an edit operation
  };

  const handleDeleteClick = async (teacher) => {
    try {
      const response = await teacherService.isTeacherInUse(teacher.teachid);
      const isInUse = response.data.inUse;
      if (isInUse) {
        setDialogTitle('Action Not Allowed');
        setConfirmMessage('Teacher is currently in use and cannot be deleted.');
        setConfirmDelete(true);
        return;
      }
    } catch (error) {
      console.error('Failed to check if teacher is in use:', error);
    }

    setTeacherToDelete(teacher);
    setDialogTitle('Confirm Deletion');
    setConfirmMessage('Are you sure you want to delete this teacher? This action cannot be undone.');
    setConfirmDelete(true);
  };

  const handleDelete = async () => {
    try {
      await teacherService.deleteTeacher(teacherToDelete.teachid);
      setConfirmDelete(false);
      setTeacherToDelete(null);
      fetchProgramsAndTeachers();
    } catch (error) {
      console.error('Failed to delete the teacher:', error);
      setConfirmMessage(error.response.data || 'Failed to delete the teacher.');
    }
  };

  const handleMarkLeftClick = (teacher) => {
    setTeacherToMarkLeft(teacher);
    setDialogTitle('Confirm Mark as Left');
    setConfirmMessage('Are you sure you want to mark this teacher as left? This action cannot be undone.');
    setConfirmMarkLeft(true);
  };

  const handleMarkLeft = async () => {
    try {
      await teacherService.markTeacherLeft(teacherToMarkLeft.teachid);
      setConfirmMarkLeft(false);
      setTeacherToMarkLeft(null);
      fetchProgramsAndTeachers();
    } catch (error) {
      console.error('Failed to mark the teacher as left:', error);
      setConfirmMessage(error.response.data || 'Failed to mark the teacher as left.');
    }
  };

  const toggleForm = () => {
    setShowForm(!showForm);
    setShowEditForm(false);
    setFormError('');

    if (!showForm) {
      setFormData({ teachname: '', teachcnic: '', teachemail: '', progid: '' });
      setFormError('');
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
    return emailRegex.test(email);
  };

  const validateCNIC = (CNIC) => {
    return CNIC >= 1000000000000 && CNIC <= 9999999999999;
  };

  const validateName = (name) => {
    const formattedName = formatName(name);
    const NameRegex = /^[A-Z]+(?:[ ][A-Z]+)*$/;
    return NameRegex.test(formattedName);
  };

  const allFieldsFilled = Object.values(formData).every(val => val) && /^[a-zA-Z0-9._%+-]+@gmail\.com$/.test(formData.teachemail) && validateCNIC(formData.teachcnic) && validateName(formData.teachname);

  return (
    <Box display="flex" justifyContent="center" sx={{ mt: 4, mb: 4, ml: matches ? '280px' : 0, width: matches ? `calc(100% - 280px)` : '100%' }}>
      <Container maxWidth="lg">
        <Typography variant="h4" sx={{ mb: 3 }}>Teachers</Typography>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button
            startIcon={showForm ? <Close /> : <Add />}
            variant="contained"
            style={{ backgroundColor: '#6c63ff', color: 'white' }}
            onClick={toggleForm}
            sx={{ borderRadius: 2, boxShadow: '0 3px 5px 2px rgba(105, 140, 255, .3)' }}
          >
            {showForm ? 'Cancel' : 'Add Teacher'}
          </Button>
        </Box>
        {showForm && (
          <Card elevation={12} sx={{ mb: 3, p: 2, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>{showEditForm ? 'Edit Teacher' : 'Add New Teacher'}</Typography>
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {programs.length > 0 ? (
                  <TextField select name="progid" label="Program" value={formData.progid} onChange={handleChange} required fullWidth InputLabelProps={{ shrink: true }}>
                    {programs.map((program) => (
                      <MenuItem key={program.progid} value={program.progid}>{program.progname}</MenuItem>
                    ))}
                  </TextField>
                ) : <Typography>No programs available</Typography>}
                <TextField name="teachname" label="Teacher Name" value={formData.teachname} 
                onChange={handleChange} required fullWidth InputLabelProps={{ shrink: true }} 
                error={formData.teachname && !validateName(formData.teachname)}
                  helperText={formData.teachname && !validateName(formData.teachname) && 'Only Use English Alphabets & Spaces'}
                />
                <TextField name="teachcnic" label="CNIC" type="number" value={formData.teachcnic} onChange={handleChange}
                  required fullWidth inputProps={{ min: 1000000000000, max: 9999999999999 }} InputLabelProps={{ shrink: true }}
                  error={formData.teachcnic && !validateCNIC(formData.teachcnic)}
                  helperText={formData.teachcnic && !validateCNIC(formData.teachcnic) && 'Please enter a valid CNIC'}
                />
                <TextField
                  name="teachemail"
                  label="Email"
                  type="email"
                  value={formData.teachemail}
                  onChange={handleChange}
                  required
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  error={formData.teachemail && !validateEmail(formData.teachemail)}
                  helperText={formData.teachemail && !validateEmail(formData.teachemail) && 'Please enter a valid Gmail address'}
                />
                <Button type="submit" variant="contained" disabled={!allFieldsFilled} sx={{ mt: 2 }} style={{ backgroundColor: '#6c63ff', color: 'white' }}>
                  {showEditForm ? 'Update Teacher' : 'Add Teacher'}
                </Button>
                {formError && (
                  <Typography color="error" sx={{ mt: 2 }}>{formError}</Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        )}
        <TableContainer component={Paper} elevation={12} sx={{
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          border: '1px solid rgba(0,0,0,0.12)'
        }}>
          <Table aria-label="teachers table">
            <TableHead sx={{ bgcolor: '#e0e0e0' }}>
              <TableRow sx={{ '& th': { fontWeight: 'bold' } }}>
                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Sr. No.</TableCell>
                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Teacher ID</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">CNIC</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Program</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teachers.map((teacher, index) => (
                <TableRow key={teacher.teachid}>
                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{index + 1}</TableCell>
                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)', whiteSpace: 'nowrap' }}>{teacher.teachid}</TableCell>
                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)', whiteSpace: 'nowrap' }}>{teacher.teachname}</TableCell>
                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{teacher.teachcnic}</TableCell>
                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{teacher.teachemail}</TableCell>
                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{teacher.progname}</TableCell>
                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{teacher.teachleftstatus ? 'Left' : 'Active'}</TableCell>
                  <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                    <IconButton color="primary" onClick={() => handleEdit(teacher)}>
                      <Edit />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleDeleteClick(teacher)}>
                      <Delete />
                    </IconButton>
                    {!teacher.teachleftstatus && (
                      <IconButton color="warning" onClick={() => handleMarkLeftClick(teacher)}>
                        <ExitToApp />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          open={confirmDelete}
          onClose={() => setConfirmDelete(false)}
        >
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {confirmMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDelete(false)} color="primary">
              Cancel
            </Button>
            {!confirmMessage.includes('in use') && (
              <Button onClick={handleDelete} color="primary">
                Confirm
              </Button>
            )}
          </DialogActions>
        </Dialog>
        <Dialog
          open={confirmMarkLeft}
          onClose={() => setConfirmMarkLeft(false)}
        >
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {confirmMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmMarkLeft(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleMarkLeft} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

function formatName(name) {
  let formattedName = name.trim().replace(/\s+/g, ' ');
  return formattedName.toUpperCase();
}

export default TeacherPage;
