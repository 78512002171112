import React, { useState, useEffect, useCallback } from 'react';
import {
  Container, Typography, Button, TextField, Box, Card, CardContent, MenuItem, useMediaQuery,
  useTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import AssignedCoursesDropdown from '../components/AssignedCoursesDropdown';
import cloService from '../api/cloService';
import taxonomyDomainService from '../api/taxonomyDomainService';

const CloEntryPage = () => {
  const [formData, setFormData] = useState({
    assignedcrsid: '',
    cloCount: '',
    clos: [],
    progid: ''
  });
  const [domains, setDomains] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState('');
  const [plos, setPLOs] = useState([]);
  const [formError, setFormError] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [availableCLOIDs, setAvailableCLOIDs] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  useEffect(() => {
    fetchDomains();
    setAvailableCLOIDs(Array.from({ length: 20 }, (_, i) => `CLO${i + 1}`));
  }, []);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (formData.assignedcrsid) {
      fetchPLOs(formData.assignedcrsid);
    }
  }, [formData.assignedcrsid]);

  const validateForm = useCallback(() => {
    const isFormValid = formData.assignedcrsid &&
      selectedDomain &&
      formData.cloCount > 0 &&
      formData.clos.every(clo => clo.cloid && clo.description && clo.level && clo.plo && validateDes(clo.description));
    setIsValid(isFormValid);
  }, [formData, selectedDomain]);

  useEffect(() => {
    validateForm();
  }, [formData, selectedDomain, validateForm]);

  const fetchDomains = async () => {
    try {
      const { data } = await taxonomyDomainService.getTaxonomyDomains();
      setDomains(data);
    } catch (error) {
      console.error('Failed to fetch domains:', error.message);
    }
  };

  const fetchPLOs = async (assignedCourseId) => {
    try {
      const { data: assignedCourseData } = await cloService.getAssignedCourseBatchId(assignedCourseId);
      const { data: batchData } = await cloService.getBatchProgId(assignedCourseData);
      const { data: plosData } = await cloService.getPLOsByProgramId(batchData);
      setPLOs(plosData);
      setFormData(prev => ({ ...prev, progid: batchData }));
    } catch (error) {
      console.error('Failed to fetch PLOs:', error.message);
      setPLOs([]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormError('');
    setFormData(prev => ({ ...prev, [name]: value }));
    if (name === 'assignedcrsid') {
      fetchPLOs(value);
    } else if (name === 'cloCount') {
      const newCount = Math.max(0, Math.min(20, Number(value)));
      setFormData(prev => {
        const newClos = Array.from({ length: newCount }, (_, i) => ({
          cloid: prev.clos[i] ? prev.clos[i].cloid : '',
          description: prev.clos[i] ? prev.clos[i].description : '',
          level: prev.clos[i] ? prev.clos[i].level : '',
          plo: prev.clos[i] ? prev.clos[i].plo : ''
        }));
        return { ...prev, clos: newClos };
      });
    }
  };

  const handleCLOChange = (index, field, value) => {
    let updatedCLOs = [...formData.clos];
    updatedCLOs[index] = { ...updatedCLOs[index], [field]: value };

    if (field === 'cloid') {
      setAvailableCLOIDs(prev => {
        const newAvailableCLOIDs = Array.from({ length: 20 }, (_, i) => `CLO${i + 1}`);
        updatedCLOs.forEach(clo => {
          if (clo.cloid) {
            const idx = newAvailableCLOIDs.indexOf(clo.cloid);
            if (idx !== -1) {
              newAvailableCLOIDs.splice(idx, 1);
            }
          }
        });
        return newAvailableCLOIDs;
      });
    }

    setFormData({ ...formData, clos: updatedCLOs });
    setFormError('');
  };

  const handleDomainChange = (e) => {
    setFormError('');
    setSelectedDomain(e.target.value);
    setFormData(prev => ({
      ...prev,
      clos: prev.clos.map(clo => ({ ...clo, level: '' }))
    }));
  };

  const handleConfirmSubmit = async () => {
    setConfirmDialogOpen(false);

    if (!isValid) return;

    const closData = formData.clos.map(clo => ({
      cloid: clo.cloid,
      clodescription: formatCLODescription(clo.description),
      domainid: selectedDomain,
      domainlevel: clo.level,
      ploid: clo.plo
    }));

    const payload = {
      assignedcrsid: formData.assignedcrsid,
      progid: formData.progid,
      clos: closData
    };

    try {
      await cloService.defineCLOs(payload);
      setFormData({ assignedcrsid: '', cloCount: '', clos: [] });
      setSelectedDomain('');
      setFormError('');
      setAvailableCLOIDs(Array.from({ length: 20 }, (_, i) => `CLO${i + 1}`));
    } catch (error) {
      setFormError(error.response?.data || error.message || 'Failed to define CLOs');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setConfirmDialogOpen(true);
  };

  const validateDes = (Des) => {
    const formattedDes = formatCLODescription(Des);
    const DesRegex = /^(?!.*\d)([A-Z]+[ ,.!?;:()/"'-]*)+[.]$/;
    return DesRegex.test(formattedDes);
  };

  return (
    <Box display="flex" justifyContent="center" sx={{ mt: 4, mb: 4, ml: matches ? '280px' : 0, width: matches ? `calc(100% - 280px)` : '100%' }}>
      <Container maxWidth="lg">
        <Typography variant="h4" sx={{ mb: 3 }}>Course Learning Outcomes</Typography>
        <Card elevation={12} sx={{ mb: 3, p: 2, borderRadius: 2 }}>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 2 }}>Define CLOs</Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <AssignedCoursesDropdown label="Assigned Course" name="assignedcrsid" value={formData.assignedcrsid} onChange={handleChange} required fullWidth />
              <TextField
                select
                name="domain"
                label="Domain"
                value={selectedDomain}
                onChange={handleDomainChange}
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
              >
                {domains.map((domain) => (
                  <MenuItem key={domain.domainid} value={domain.domainid}>
                    {domain.domainname}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                name="cloCount"
                label="Number of CLOs"
                type="number"
                value={formData.cloCount}
                onChange={handleChange}
                required
                fullWidth
                inputProps={{ min: 1, max: 20 }}
                InputLabelProps={{ shrink: true }}
              />
              {formData.clos.map((clo, index) => (
                <Box key={index} sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <TextField select label="CLO ID" value={clo.cloid} onChange={(e) => handleCLOChange(index, 'cloid', e.target.value)} required fullWidth>
                    {availableCLOIDs.concat(clo.cloid).map((cloid) => (
                      <MenuItem key={cloid} value={cloid}>
                        {cloid}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    label="Description"
                    name={`description-${index}`}
                    value={clo.description}
                    onChange={(e) => handleCLOChange(index, 'description', e.target.value)}
                    required
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={clo.description && !validateDes(clo.description)}
                    helperText={clo.description && !validateDes(clo.description) && 'Only Use English Alphabets, Spaces and Allowed Punctuation: " \' . , ! ? ; : ( ) / -'}
                  />
                  <TextField select name={`level-${index}`} label="Level" value={clo.level} onChange={(e) => handleCLOChange(index, 'level', e.target.value)} required fullWidth InputLabelProps={{ shrink: true }}>
                    {domains.find(d => d.domainid === selectedDomain)?.levels.map((level) => (
                      <MenuItem key={level.domainlevel} value={level.domainlevel}>
                        {level.domainlevel} - {level.levelname}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField select name={`plo-${index}`} label="PLO" value={clo.plo} onChange={(e) => handleCLOChange(index, 'plo', e.target.value)} required fullWidth InputLabelProps={{ shrink: true }}>
                    {plos.map((plo) => (
                      <MenuItem key={plo.ploid} value={plo.ploid}>
                        {plo.ploid} - {plo.plodescription}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              ))}
              <Button type="submit" variant="contained" sx={{ mt: 2 }} disabled={!isValid} style={{ backgroundColor: '#6c63ff', color: 'white' }}>
                Define CLOs
              </Button>
              {formError && (
                <Typography color="error" sx={{ mt: 2 }}>{formError}</Typography>
              )}
            </Box>
          </CardContent>
        </Card>

        <Dialog
          open={confirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
        >
          <DialogTitle>Confirm Submission</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to define these CLOs? You will not be able to change them later.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmSubmit} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

const formatCLODescription = description => {
  let formattedDescription = description.trim();
  formattedDescription = formattedDescription.replace(/\s+/g, ' ');

  if (!formattedDescription.endsWith('.')) {
    formattedDescription += '.';
  }

  return formattedDescription.toUpperCase();
};

export default CloEntryPage;
