import React, { useState, useEffect } from 'react';
import {
  Container, Typography, Button, TextField, Box, FormGroup, FormControlLabel, Checkbox,
  Card, CardContent, MenuItem, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, useMediaQuery, useTheme, Collapse, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { Add, Close, Edit, Delete } from '@mui/icons-material';
import assignedCourseService from '../api/assignedCourseService';
import courseService from '../api/courseService';
import batchService from '../api/batchService';

const AssignCoursePage = () => {
  const [assignedCourses, setAssignedCourses] = useState([]);
  const [openStudents, setOpenStudents] = useState({});
  const [teachers, setTeachers] = useState([]);
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchEnrollments, setBatchEnrollments] = useState([]);
  const [formError, setFormError] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    crscode: '',
    teachid: '',
    batchid: '',
    semester: '',
    numBatches: '',
    attainmentpassingscore: 40
  });
  const [editMode, setEditMode] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);
  const [dialogTitle, setDialogTitle] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('');

  useEffect(() => {
    fetchAssignedCourses();
    fetchTeachers();
    fetchCourses();
    fetchBatches();
  }, []);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const fetchAssignedCourses = async () => {
    const response = await assignedCourseService.getAssignedCourses();
    setAssignedCourses(response.data);
    setOpenStudents(response.data.reduce((acc, course) => ({ ...acc, [course.assignedcrsid]: false }), {}));
  };

  const toggleStudentVisibility = (id) => {
    setOpenStudents(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const handleSelectAllStudents = (batchIndex, isChecked) => {
    const updatedBatchEnrollments = [...batchEnrollments];
    const currentBatch = updatedBatchEnrollments[batchIndex];
    const selectedStudents = {};
    currentBatch.students.forEach(student => {
      selectedStudents[student.stuid] = isChecked;
    });
    updatedBatchEnrollments[batchIndex].selectedStudents = selectedStudents;
    setBatchEnrollments(updatedBatchEnrollments);
  };

  const fetchTeachers = async () => {
    const response = await assignedCourseService.getCurrentTeachers();
    setTeachers(response.data);
  };

  const fetchCourses = async () => {
    const response = await courseService.getCourses();
    setCourses(response.data);
  };

  const fetchBatches = async () => {
    const response = await batchService.getBatches();
    setBatches(response.data);
  };

  const handleChange = (e) => {
    if (formError) {
      setFormError('');
    }
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNumBatchesChange = (e) => {
    if (formError) {
      setFormError('');
    }
    const rawValue = e.target.value;
    const newNumBatches = Number(rawValue);

    if (newNumBatches > 10) {
      setFormData(prevFormData => ({ ...prevFormData, numBatches: '10' }));
      adjustBatchEnrollments(10);
    } else if (rawValue === '' || newNumBatches < 1) {
      setFormData(prevFormData => ({ ...prevFormData, numBatches: '' }));
      adjustBatchEnrollments(0);
    } else {
      setFormData(prevFormData => ({ ...prevFormData, numBatches: rawValue }));
      adjustBatchEnrollments(newNumBatches);
    }
  };

  const adjustBatchEnrollments = (newNumBatches) => {
    const newBatchEnrollments = new Array(newNumBatches).fill().map((_, index) => ({
      batchId: batchEnrollments[index] ? batchEnrollments[index].batchId : '',
      students: batchEnrollments[index] ? batchEnrollments[index].students : [],
      selectedStudents: batchEnrollments[index] ? batchEnrollments[index].selectedStudents : {}
    }));
    setBatchEnrollments(newBatchEnrollments);
  };

  const handleBatchSelection = async (index, value) => {
    if (formError) {
      setFormError('');
    }
    const newBatchEnrollments = batchEnrollments.map((batch, idx) => {
      if (idx === index) {
        return { ...batch, batchId: value, students: [], selectedStudents: {} };
      }

      return batch;
    });
    setBatchEnrollments(newBatchEnrollments);

    try {
      const response = await assignedCourseService.getBatchStudents(value);
      const updatedBatchEnrollments = newBatchEnrollments.map((batch, idx) => {
        if (idx === index) {
          return { ...batch, students: response.data };
        }

        return batch;
      });
      setBatchEnrollments(updatedBatchEnrollments);
    } catch (error) {
      console.error('Failed to fetch students:', error);
    }
  };

  const getAvailableBatches = (currentIndex) => {
    const selectedBatchIds = batchEnrollments.map(enrollment => enrollment.batchId);
    return batches.filter(batch => !selectedBatchIds.includes(batch.batchid) || batch.batchid === batchEnrollments[currentIndex].batchId);
  };

  const handleStudentCheckbox = (batchIndex, studentId) => {
    const updatedBatchEnrollments = [...batchEnrollments];
    updatedBatchEnrollments[batchIndex].selectedStudents[studentId] = !updatedBatchEnrollments[batchIndex].selectedStudents[studentId];
    setBatchEnrollments(updatedBatchEnrollments);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!editMode && !formData.numBatches) {
      setFormError('Number of batches is required.');
      return;
    }

    try {
      const studentIds = batchEnrollments.flatMap(batch => Object.keys(batch.selectedStudents).filter(id => batch.selectedStudents[id]));
      const courseData = {
        crscode: formData.crscode,
        teachid: formData.teachid,
        batchid: formData.batchid,
        semester: formData.semester,
        attainmentpassingscore: formData.attainmentpassingscore,
        students: studentIds
      };

      if (editMode) {
        await assignedCourseService.updateAssignedCourse(formData.assignedcrsid, courseData);
      } else {
        await assignedCourseService.assignCourse(courseData);
      }
      fetchAssignedCourses();
      setShowForm(false);
      setFormData({ crscode: '', teachid: '', batchid: '', semester: '', numBatches: '', attainmentpassingscore: 40 });
      setBatchEnrollments([]);
      setEditMode(false);
    } catch (error) {
      setFormError(error.response.data);
    }
  };

  const handleEdit = (course) => {
    setFormData({
      assignedcrsid: course.assignedcrsid,
      crscode: course.crscode,
      teachid: course.teachid,
      batchid: course.batchid,
      semester: course.semester,
      numBatches: course.batches ? course.batches.length : 0,
      attainmentpassingscore: course.attainmentpassingscore
    });

    if (course.batches) {
      setBatchEnrollments(course.batches.map(batch => ({
        batchId: batch.batchid,
        students: batch.students || [],
        selectedStudents: batch.students ? batch.students.reduce((acc, student) => ({ ...acc, [student.stuid]: true }), {}) : {}
      })));
    } else {
      setBatchEnrollments([]);
    }

    setEditMode(true);
    setShowForm(true);
  };

  const handleDeleteClick = (course) => {
    setCourseToDelete(course);
    setDialogTitle('Confirm Deletion');
    setConfirmMessage('Are you sure you want to delete this assigned course? This action cannot be undone.');
    setConfirmDelete(true);
  };

  const handleDelete = async () => {
    try {
      await assignedCourseService.deleteAssignedCourse(courseToDelete.assignedcrsid);
      setConfirmDelete(false);
      setCourseToDelete(null);
      fetchAssignedCourses();
    } catch (error) {
      setConfirmMessage('Course is in use.');
    }
  };

  const toggleForm = () => {
    setShowForm(!showForm);
    setFormError('');

    if (!showForm) {
      setFormData({ crscode: '', teachid: '', batchid: '', semester: '', numBatches: '', attainmentpassingscore: 40 });
      setFormError('');
      setBatchEnrollments([]);
      setEditMode(false);
    }
  };

  const handleUnenrollStudent = async (assignedCourseId, studentId) => {
    try {
      await assignedCourseService.unenrollStudent(assignedCourseId, studentId);
      fetchAssignedCourses();
    } catch (error) {
      console.error('Error unenrolling student:', error);
    }
  };

  const markAttendanceShort = async (assignedCourseId, studentId) => {
    try {
      await assignedCourseService.markAttendanceShort(assignedCourseId, studentId);
      fetchAssignedCourses();
    } catch (error) {
      console.error('Error marking attendance short:', error);
    }
  };

  const allFieldsFilled = formData.crscode && formData.teachid && formData.batchid && formData.semester &&
    (editMode || formData.numBatches) &&
    batchEnrollments.every(batch => batch.batchId && Object.values(batch.selectedStudents).some(selected => selected));

  return (
    <Box display="flex" justifyContent="center" sx={{ mt: 4, mb: 4, ml: matches ? '280px' : 0, width: matches ? `calc(100% - 280px)` : '100%' }}>
      <Container maxWidth="lg">
        <Typography variant="h4" sx={{ mb: 3 }}>Assigned Courses</Typography>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button
            startIcon={showForm ? <Close /> : <Add />}
            variant="contained"
            style={{ backgroundColor: '#6c63ff', color: 'white' }}
            onClick={toggleForm}
            sx={{ borderRadius: 2, boxShadow: '0 3px 5px 2px rgba(105, 140, 255, .3)' }}
          >
            {showForm ? 'Cancel' : 'Assign Course'}
          </Button>
        </Box>
        {showForm && (
          <Card elevation={12} sx={{ mb: 3, p: 2, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>{editMode ? 'Edit Assigned Course' : 'Assign a New Course'}</Typography>
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField select label="Course Code" name="crscode" value={formData.crscode} onChange={handleChange} fullWidth required InputLabelProps={{ shrink: true }} disabled={editMode}>
                  {courses.map((course) => (
                    <MenuItem key={course.crscode} value={course.crscode}>{course.crscode} - {course.crsname}</MenuItem>
                  ))}
                </TextField>
                <TextField select label="Teacher" name="teachid" value={formData.teachid} onChange={handleChange} fullWidth required InputLabelProps={{ shrink: true }}>
                  {teachers.map((teacher) => (
                    <MenuItem key={teacher.teachid} value={teacher.teachid}>{teacher.teachid} - {teacher.teachname}</MenuItem>
                  ))}
                </TextField>
                <TextField select label="Offer Class" name="batchid" value={formData.batchid} onChange={handleChange} fullWidth required InputLabelProps={{ shrink: true }} disabled={editMode}>
                  {batches.map((batch) => (
                    <MenuItem key={batch.batchid} value={batch.batchid}>{batch.batchid}</MenuItem>
                  ))}
                </TextField>
                <TextField select name="semester" label="Semester" value={formData.semester} onChange={handleChange} required fullWidth InputLabelProps={{ shrink: true }} disabled={editMode}>
                  {['REGULAR', 'SUMMER'].map((semester) => (
                    <MenuItem key={semester} value={semester}>{semester}</MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  label="Attainment Passing Score"
                  name="attainmentpassingscore"
                  value={formData.attainmentpassingscore}
                  onChange={handleChange}
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                >
                  {Array.from({ length: 61 }, (_, i) => i + 40).map(score => (
                    <MenuItem key={score} value={score}>{score}</MenuItem>
                  ))}
                </TextField>
                <TextField label="Number of Batches to Enroll" type="number" name="numBatches" value={formData.numBatches} onChange={handleNumBatchesChange} fullWidth required InputProps={{ inputProps: { min: 1, max: 10 } }} InputLabelProps={{ shrink: true }} disabled={editMode} />
                {batchEnrollments.map((batch, index) => (
                  <FormGroup key={index}>
                    <TextField
                      select
                      label="Batch"
                      value={batch.batchId}
                      onChange={(e) => handleBatchSelection(index, e.target.value)}
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                    >
                      {getAvailableBatches(index).map(batchOption => (
                        <MenuItem key={batchOption.batchid} value={batchOption.batchid}>{batchOption.batchid}</MenuItem>
                      ))}
                    </TextField>
                    {batch.batchId && (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => handleSelectAllStudents(index, e.target.checked)}
                              checked={Object.keys(batch.selectedStudents).length > 0 &&
                                Object.values(batch.selectedStudents).every(Boolean)}
                              indeterminate={Object.values(batch.selectedStudents).some(Boolean) && !Object.values(batch.selectedStudents).every(Boolean)}
                            />
                          }
                          label="Select All"
                        />
                        {batch.students.map(student => (
                          <FormControlLabel
                            key={student.stuid}
                            control={
                              <Checkbox
                                checked={!!batch.selectedStudents[student.stuid]}
                                onChange={() => handleStudentCheckbox(index, student.stuid)}
                              />
                            }
                            label={`${student.stuid} (${student.stuname})`}
                          />
                        ))}
                      </>
                    )}
                  </FormGroup>
                ))}
                <Button type="submit" disabled={!allFieldsFilled} variant="contained" sx={{ mt: 2 }} style={{ backgroundColor: '#6c63ff', color: 'white' }}>
                  {editMode ? 'Update Course' : 'Submit'}
                </Button>
                {formError && <Typography color="error" sx={{ mt: 2 }}>{formError}</Typography>}
              </Box>
            </CardContent>
          </Card>
        )}
        <TableContainer component={Paper} elevation={12} sx={{
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          border: '1px solid rgba(0,0,0,0.12)'
        }}>
          <Table>
            <TableHead sx={{ bgcolor: '#e0e0e0' }}>
              <TableRow sx={{ '& th': { fontWeight: 'bold' } }}>
                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Sr. No.</TableCell>
                <TableCell align="center">Assigned Course ID</TableCell>
                <TableCell align="center">Course Code</TableCell>
                <TableCell align="center">Teacher ID</TableCell>
                <TableCell align="center">Teacher Name</TableCell>
                <TableCell align="center">Offer Class</TableCell>
                <TableCell align="center">Semester</TableCell>
                <TableCell align="center">Attainment Passing Score</TableCell>
                <TableCell align="center">Course Completed</TableCell>
                <TableCell align="center">Students</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assignedCourses.map((course, index) => (
                <React.Fragment key={course.assignedcrsid}>
                  <TableRow>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{index + 1}</TableCell>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{course.assignedcrsid}</TableCell>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{course.crscode}</TableCell>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{course.teachid}</TableCell>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{course.teachname}</TableCell>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{course.batchid}</TableCell>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{course.semester}</TableCell>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{course.attainmentpassingscore}</TableCell>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{course.crscompleted ? "Yes" : "No"}</TableCell>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>
                      <Button onClick={() => toggleStudentVisibility(course.assignedcrsid)}>
                        {openStudents[course.assignedcrsid] ? "Hide Students" : "View Students"}
                      </Button>
                    </TableCell>
                    <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                      <IconButton color="primary" onClick={() => handleEdit(course)} disabled={course.crscompleted}>
                        <Edit />
                      </IconButton>
                      <IconButton color="secondary" onClick={() => handleDeleteClick(course)} disabled={course.crscompleted}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                      <Collapse in={openStudents[course.assignedcrsid]} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                          <Typography variant="h6" gutterBottom component="div">Students</Typography>
                          <Table size="small" aria-label="students">
                            <TableHead>
                              <TableRow sx={{ '& th': { fontWeight: 'bold' } }}>
                                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Student ID</TableCell>
                                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Name</TableCell>
                                <TableCell align="center">Actions</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {course.students.map((student) => (
                                <TableRow key={student.stuid}>
                                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)', whiteSpace: 'nowrap' }}>{student.stuid}</TableCell>
                                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)', whiteSpace: 'nowrap' }}>{student.stuname}</TableCell>
                                  <TableCell align="center">
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => handleUnenrollStudent(course.assignedcrsid, student.stuid)}
                                      disabled={course.crscompleted}
                                    >
                                      Unenroll
                                    </Button>
                                    <Button
                                      variant="outlined"
                                      color="secondary"
                                      onClick={() => markAttendanceShort(course.assignedcrsid, student.stuid)}
                                      disabled={course.crscompleted || student.attendshort}
                                      sx={{ ml: 1 }}
                                    >
                                      Attendance Short
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog
          open={confirmDelete}
          onClose={() => setConfirmDelete(false)}
        >
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {confirmMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDelete(false)} color="primary">
              Cancel
            </Button>
            {!confirmMessage.includes('in use') && (
              <Button onClick={handleDelete} color="primary">
                Confirm
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

export default AssignCoursePage;
