import React, { useState } from 'react';
import {
  Container, Typography, Button, TextField, Box, Card, CardContent, useMediaQuery,
  useTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import AssignedCoursesDropdown from '../components/AssignedCoursesDropdown';
import assessmentWeightageService from '../api/assessmentWeightageService';

const AssessmentWeightage = () => {
  const [formData, setFormData] = useState({
    assignedcrsid: '',
    QUIZ: '',
    ASSIGNMENT: '',
    MID_TERM: '',
    FINAL_TERM: '',
    PROJECT: ''
  });
  const [formError, setFormError] = useState('');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const assessmentTypes = ['QUIZ', 'ASSIGNMENT', 'MID_TERM', 'FINAL_TERM', 'PROJECT'];

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setFormError('');
  };

  const handleConfirmSubmit = async () => {
    setConfirmDialogOpen(false);

    try {
      if (!formData.assignedcrsid) {
        throw new Error('Please select an assigned course.');
      }

      const assessmentWeightages = assessmentTypes.map(type => ({
        assessmenttype: type,
        assessmentweightage: parseInt(formData[type] || 0, 10)
      }));

      await assessmentWeightageService.setAssessmentWeightages({
        assessments: assessmentWeightages,
        assignedcrsid: formData.assignedcrsid
      });

      setFormData({ assignedcrsid: '', QUIZ: '', ASSIGNMENT: '', MID_TERM: '', FINAL_TERM: '', PROJECT: '' });
      setFormError('');

    } catch (error) {
      setFormError(error.response?.data || error.message || 'Failed to set assessment weightages');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setConfirmDialogOpen(true);
  };

  const validateWeightage = (weightage) => {
    return weightage >= 1 && weightage <= 100;
  };

  const allFieldsFilled = formData.assignedcrsid && assessmentTypes.every(type => formData[type] && validateWeightage(formData[type]));

  return (
    <Box display="flex" justifyContent="center" sx={{ mt: 4, mb: 4, ml: matches ? '280px' : 0, width: matches ? `calc(100% - 280px)` : '100%' }}>
      <Container maxWidth="lg">
        <Typography variant="h4" sx={{ mb: 3 }}>Assessment Weightages</Typography>
        <Card elevation={12} sx={{ mb: 3, p: 2, borderRadius: 2 }}>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 2 }}>Set Assessment Weightages</Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <AssignedCoursesDropdown label="Assigned Course" name="assignedcrsid" value={formData.assignedcrsid} onChange={handleChange} required fullWidth />
              {assessmentTypes.map((type, index) => (
                <TextField
                  key={index}
                  name={type}
                  label={`${type} Weightage`}
                  type="number"
                  value={formData[type] || ''}
                  onChange={handleChange}
                  required
                  fullWidth
                  inputProps={{ min: 1, max: 100 }}
                  InputLabelProps={{ shrink: true }}
                  error={formData[type] && !validateWeightage(formData[type])}
                  helperText={formData[type] && !validateWeightage(formData[type]) && 'Assessment Weightage must be between 1 and 100.'}
                />
              ))}
              <Button type="submit" variant="contained" sx={{ mt: 2 }} disabled={!allFieldsFilled} style={{ backgroundColor: '#6c63ff', color: 'white' }}>Set Weightages</Button>
              {formError && (
                <Typography color="error" sx={{ mt: 2 }}>{formError}</Typography>
              )}
            </Box>
          </CardContent>
        </Card>

        <Dialog
          open={confirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
        >
          <DialogTitle>Confirm Submission</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to set these assessment weightages? You will not be able to change them later.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmSubmit} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

export default AssessmentWeightage;
