import React, { useState, useEffect } from 'react';
import {
  Container, Typography, CircularProgress, MenuItem,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useMediaQuery,
  useTheme, Box, TextField, styled, Button
} from '@mui/material';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import attainmentService from '../api/attainmentService';
import assignedCourseService from '../api/assignedCourseService';
import batchService from '../api/batchService';

const StickyTableCell = styled(TableCell)(({ theme, position }) => ({
  position: 'sticky',
  left: position === 'first' ? 0 : position === 'second' ? 60 : 140,
  background: theme.palette.background.paper,
  zIndex: 5
}));

const ProgramAttainmentPage = () => {
  const [batches, setBatches] = useState([]);
  const [selectedBatchId, setSelectedBatchId] = useState('');
  const [students, setStudents] = useState([]);
  const [ploAttainment, setPLOAttainment] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchBatches();
  }, []);

  const fetchBatches = async () => {
    setLoading(true);

    try {
      const response = await batchService.getBatches();
      setBatches(response.data);
      setError('');
    } catch (error) {
      setError('Failed to fetch batches');
    }
    setLoading(false);
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const handleBatchChange = async (event) => {
    const batchId = event.target.value;
    setSelectedBatchId(batchId);
    setLoading(true);
    setError('');

    try {
      const studentsRes = await assignedCourseService.getBatchStudents(batchId);
      setStudents(studentsRes.data || []);

      const attainmentRes = await attainmentService.fetchProgramAttainmentData(batchId);
      if (attainmentRes.data.error) {
        setError(attainmentRes.data.error);
        setPLOAttainment({});
      } else {
        setPLOAttainment(attainmentRes.data);
        setError('');
      }
    } catch (error) {
      setError('Failed to fetch program attainment data');
    }

    setLoading(false);
  };

  const exportPDF = () => {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'pt',
      format: 'a4'
    });

    const title = 'Air University';
    const subtitle = "Class Wise Cumulative PLO Attainment Report";
    const currentDate = new Date();
    const dateStr = currentDate.toDateString();
    const timeStr = currentDate.toLocaleTimeString();

    // Date and time
    doc.setFontSize(10);
    doc.text(`Date: ${dateStr} Time: ${timeStr}`, doc.internal.pageSize.getWidth() - 200, 20);

    // Title
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(24);
    doc.text(title, doc.internal.pageSize.getWidth() / 2, 40, { align: 'center' });

    // Subtitle
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(18);
    doc.text(subtitle, doc.internal.pageSize.getWidth() / 2, 70, { align: 'center' });

    // Class
    const offerClass = selectedBatchId;

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(12);
    doc.text(`Class: ${offerClass}`, 40, 100);

    let startY = 130;

    const drawTableWithHeader = (header, data, startY, label) => {
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(14);
      doc.setTextColor(100);
      doc.text(label, 40, startY + 15);

      const headers = [["SNO", "Reg. ID", "Student Name", ...Object.keys(data[Object.keys(data)[0] || {}]).sort().map(id => `${id}`)]];

      const tableData = students.map((student, index) => [
        index + 1,
        student.stuid,
        student.stuname,
        ...Object.keys(data[student.stuid] || {}).sort().map(ploId => `${data[student.stuid][ploId].percentage}% (${data[student.stuid][ploId].status})`)
      ]);

      doc.autoTable({
        head: headers,
        body: tableData,
        startY: startY + 20,
        theme: 'grid',
        headStyles: { fillColor: [22, 160, 133], textColor: 'white', fontSize: 10, fontStyle: 'bold' },
        margin: { horizontal: 40 },
        styles: {
          overflow: 'linebreak',
          fontSize: 8,
          cellPadding: 4,
          textColor: [0, 0, 0],
          minCellHeight: 10,
          halign: 'center',
          valign: 'middle',
          lineColor: [0, 0, 0],
          lineWidth: 0.5
        },
        columnStyles: {
          0: { cellWidth: 30 },
          1: { cellWidth: 70 },
          2: { cellWidth: 100 },
          text: { cellWidth: 'auto' }
        },
        willDrawCell: (data) => {
          if (data.row.section === 'body') {
            const cellContent = String(data.cell.raw).trim();
            if (cellContent.match(/\b(Fail|F)\b/)) {
              doc.setFillColor(255, 153, 153); // Light red color for fail status
              doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'F');
            }
          }
        }
      });

      return doc.lastAutoTable.finalY;
    };

    drawTableWithHeader(ploAttainment, ploAttainment, startY, "PLO Attainment");

    doc.save('program_attainment_report.pdf');
  };

  const renderPLOHeaders = () => {
    const anyStudentId = Object.keys(ploAttainment)[0];
    return anyStudentId ? Object.keys(ploAttainment[anyStudentId]).sort().map(ploId => (
      <TableCell key={ploId} align="center" sx={{ fontWeight: 'bold', bgcolor: '#e0e0e0' }} >{ploId}</TableCell>
    )) : null;
  };

  const renderPLOData = (studentId) => {
    const attainment = ploAttainment[studentId] || {};
    return Object.keys(attainment).sort().map((ploId, index) => (
      <TableCell key={index} align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)', whiteSpace: 'nowrap', backgroundColor: attainment[ploId].status === 'Fail' ? 'rgba(255, 153, 153, 0.5)' : 'inherit' }}>{`${attainment[ploId].percentage}% (${attainment[ploId].status})`}</TableCell>
    ));
  };

  return (
    <Box display="flex" justifyContent="center" sx={{ mt: 4, mb: 4, ml: matches ? '280px' : 0, width: matches ? `calc(100% - 280px)` : '100%' }}>
      <Container maxWidth="lg">
        <Typography variant="h4" sx={{ mb: 3 }}>Program Attainment</Typography>
        <TextField select name="batchid" label="Batch" value={selectedBatchId} onChange={handleBatchChange} sx={{ mb: 2 }} required fullWidth InputLabelProps={{ shrink: true }}>
          {batches.map((batch) => (
            <MenuItem key={batch.batchid} value={batch.batchid}>
              {batch.batchid}
            </MenuItem>
          ))}
        </TextField>

        {loading && <CircularProgress />}
        {error && <Typography color="error">{error}</Typography>}

        {!error && selectedBatchId && students.length > 0 && (
          <>
            <Box display="flex" justifyContent="flex-end" mb={2}>
              <Button
                variant="contained"
                onClick={exportPDF}
                style={{ backgroundColor: '#6c63ff', color: 'white' }}
                sx={{ borderRadius: 2, boxShadow: '0 3px 5px 2px rgba(105, 140, 255, .3)' }}
              >
                Export to PDF
              </Button>
            </Box>
            <Typography variant="h6" sx={{ mt: 2 }}>PLO Attainment</Typography>
            <TableContainer component={Paper} elevation={12} sx={{
              borderRadius: 2,
              boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
              border: '1px solid rgba(0,0,0,0.12)'
            }}>
              <Table>
                <TableHead sx={{ bgcolor: '#e0e0e0' }}>
                  <TableRow sx={{ '& th': { fontWeight: 'bold' } }}>
                    <StickyTableCell align="center" sx={{ bgcolor: '#e0e0e0' }} position="first" style={{ whiteSpace: 'nowrap' }}>Sr. No.</StickyTableCell>
                    <StickyTableCell align="center" sx={{ bgcolor: '#e0e0e0' }} position="second" style={{ whiteSpace: 'nowrap' }}>Reg. ID</StickyTableCell>
                    <StickyTableCell align="center" sx={{ bgcolor: '#e0e0e0' }} position="third" style={{ whiteSpace: 'nowrap' }}>Student Name</StickyTableCell>
                    {renderPLOHeaders()}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {students.map((student, index) => (
                    <TableRow key={student.stuid}>
                      <StickyTableCell align="center" position="first" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{index + 1}</StickyTableCell>
                      <StickyTableCell align="center" position="second" style={{ borderRight: '1px solid rgba(0,0,0,0.12)', whiteSpace: 'nowrap' }}>{student.stuid}</StickyTableCell>
                      <StickyTableCell align="center" position="third" style={{ borderRight: '1px solid rgba(0,0,0,0.12)', whiteSpace: 'nowrap' }}>{student.stuname}</StickyTableCell>
                      {renderPLOData(student.stuid)}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Container>
    </Box>
  );
};

export default ProgramAttainmentPage;
