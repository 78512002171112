import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useLocationState } from '../context/LocationContext';
import attainmentService from '../api/attainmentService';

const AssignedCoursesDropdown = ({ onChange, value, triggerFetch }) => {
  const [courses, setCourses] = useState([]);
  const { locationState } = useLocationState();
  const userId = locationState?.userId;

  useEffect(() => {
    const fetchAssignedCourses = async () => {
      if (userId) {
        try {
          const response = await attainmentService.getCompleteCoursesByTeacher(userId);
          if (response && response.data) {
            setCourses(response.data);
          } else {
            console.error('Empty response or missing data:', response);
          }
        } catch (error) {
          console.error('Error fetching assigned courses:', error);
        }
      }
    };

    fetchAssignedCourses();
  }, [userId, triggerFetch]);

  const handleCourseChange = (event) => {
    const selectedCourseId = event.target.value;
    onChange({
      target: {
        name: 'assignedcrsid',
        value: selectedCourseId
      }
    });
  };


  return (
    <TextField
      select
      name="assignedcrsid"
      label="Assigned Course"
      value={value}
      onChange={handleCourseChange}
      required
      fullWidth
      InputLabelProps={{ shrink: true }}
    >
      <MenuItem value="" disabled>
        Please select
      </MenuItem>
      {courses.map(course => (
        <MenuItem key={course.assignedcrsid} value={course.assignedcrsid}>
          {course.assignedcrsid}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default AssignedCoursesDropdown;
