import React, { useState, useEffect, useCallback } from 'react';
import {
    Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress,
    useMediaQuery, useTheme, Box
} from '@mui/material';
import { useLocationState } from '../context/LocationContext';
import StudentCompleteCourseDropdown from '../components/StudentCompleteCourseDropdown';
import attainmentService from '../api/attainmentService';

const StudentCourseAttainmentPage = () => {
    const [selectedCourseId, setSelectedCourseId] = useState('');
    const [cloAttainment, setCLOAttainment] = useState({});
    const [ploAttainment, setPLOAttainment] = useState({});
    const [scoreAndGrade, setScoreAndGrade] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { locationState } = useLocationState();
    const studentId = locationState?.userId;

    const fetchAttainmentData = useCallback(async (courseId) => {
        setLoading(true);
        setCLOAttainment({});
        setPLOAttainment({});
        setScoreAndGrade({});
        setError('');
        try {
            const response = await attainmentService.fetchCourseAttainmentDataForStudent(courseId, studentId);
            if (response.data.error) {
                setError(response.data.error);
                setCLOAttainment({});
                setPLOAttainment({});
                setScoreAndGrade({});
            } else {
                setCLOAttainment(response.data.cloAttainment || {});
                setPLOAttainment(response.data.ploAttainment || {});
                setScoreAndGrade(response.data.scoreAndGrade || {});
            }
        } catch (error) {
            setError('Failed to fetch course attainment data');
        } finally {
            setLoading(false);
        }
    }, [studentId]);

    useEffect(() => {
        if (selectedCourseId) {
            fetchAttainmentData(selectedCourseId);
        }
    }, [selectedCourseId, fetchAttainmentData]);

    const handleCourseChange = (event) => {
        setSelectedCourseId(event.target.value);
        setCLOAttainment({});
        setPLOAttainment({});
        setScoreAndGrade({});
        setError('');
    };

    const renderAttainmentTable = (attainmentData, type) => (
        <TableContainer component={Paper} elevation={12} sx={{
            borderRadius: 2,
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
            border: '1px solid rgba(0,0,0,0.12)'
        }}>
            <Table aria-label={`${type} attainment table`}>
                <TableHead sx={{ bgcolor: '#e0e0e0' }}>
                    <TableRow sx={{ '& th': { fontWeight: 'bold' } }}>
                        <TableCell style={{ whiteSpace: 'nowrap' }}>Sr. No.</TableCell>
                        <TableCell style={{ whiteSpace: 'nowrap' }}>{type} ID</TableCell>
                        <TableCell align="center">Percentage</TableCell>
                        <TableCell align="center">Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(attainmentData).map(([id, { percentage, status }], index) => (
                        <TableRow key={id}>
                            <TableCell style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{index + 1}</TableCell>
                            <TableCell style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{id}</TableCell>
                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{`${percentage}%`}</TableCell>
                            <TableCell align="center">{status}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const renderScoreAndGradeTable = () => (
        <TableContainer component={Paper} elevation={12} sx={{
            mt: 2,
            borderRadius: 2,
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
            border: '1px solid rgba(0,0,0,0.12)'
        }}>
            <Table aria-label="Score and Grade">
                <TableHead sx={{ bgcolor: '#e0e0e0' }}>
                    <TableRow sx={{ '& th': { fontWeight: 'bold' } }}>
                        <TableCell align="center">Score</TableCell>
                        <TableCell align="center">Grade</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{scoreAndGrade.score ? `${scoreAndGrade.score.toFixed(2)}%` : 'N/A'}</TableCell>
                        <TableCell align="center">{scoreAndGrade.grade || 'N/A'}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Box display="flex" justifyContent="center" sx={{ mt: 4, mb: 4, ml: matches ? '280px' : 0, width: matches ? `calc(100% - 280px)` : '100%' }}>
            <Container maxWidth="lg">
                <Typography variant="h4" sx={{ mb: 3 }}>My Course Attainment</Typography>
                <StudentCompleteCourseDropdown onChange={handleCourseChange} value={selectedCourseId} />

                {loading && <CircularProgress />}
                {error && <Typography color="error">{error}</Typography>}

                {!error && selectedCourseId && (
                    <>
                        <Typography variant="h6" sx={{ mt: 2 }}>Score and Grade</Typography>
                        {scoreAndGrade ? renderScoreAndGradeTable() : <Typography>No Score and Grade Data Available</Typography>}

                        <Typography variant="h6" sx={{ mt: 2 }}>CLO Attainment</Typography>
                        {Object.keys(cloAttainment).length > 0 ? renderAttainmentTable(cloAttainment, 'CLO') : <Typography>No CLO Attainment Data Available</Typography>}

                        <Typography variant="h6" sx={{ mt: 2 }}>PLO Attainment</Typography>
                        {Object.keys(ploAttainment).length > 0 ? renderAttainmentTable(ploAttainment, 'PLO') : <Typography>No PLO Attainment Data Available</Typography>}
                    </>
                )}
            </Container>
        </Box>
    );
};

export default StudentCourseAttainmentPage;
