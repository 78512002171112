import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  TextField,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  useMediaQuery,
  useTheme,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { Add, Close, Edit, Delete } from '@mui/icons-material';
import departmentService from '../api/departmentService';

const DepartmentPage = () => {
  const [departments, setDepartments] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [formError, setFormError] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('');
  const [formData, setFormData] = useState({
    deptid: null,
    deptname: '',
    deptdescription: '',
  });
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [departmentToDelete, setDepartmentToDelete] = useState(null);

  useEffect(() => {
    fetchDepartments();
  }, []);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const fetchDepartments = async () => {
    try {
      const response = await departmentService.getDepartments();
      setDepartments(response.data);
    } catch (error) {
      console.error('Failed to fetch departments:', error);
    }
  };

  const handleChange = (e) => {
    if (formError) {
      setFormError('');
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formattedDeptName = formatDeptName(formData.deptname);
    const formattedDeptDescription = formatDeptDescription(formData.deptdescription);
    const updatedFormData = {
      deptname: formattedDeptName,
      deptdescription: formattedDeptDescription,
    };

    try {
      if (formData.deptid) {
        await departmentService.updateDepartment(formData.deptid, updatedFormData);
      } else {
        await departmentService.createDepartment(updatedFormData);
      }
      setFormData({ deptid: null, deptname: '', deptdescription: '' });
      setShowForm(false);
      fetchDepartments();
    } catch (error) {
      setFormError(error.response.data);
    }
  };

  const handleEdit = (department) => {
    if (formError) {
      setFormError('');
    }
    setFormData({
      deptid: department.deptid,
      deptname: department.deptname,
      deptdescription: department.deptdescription
    });
    setShowForm(true);
  };

  const handleDeleteClick = async (department) => {
    try {
      const response = await departmentService.isDepartmentInUse(department.deptid);
      const isInUse = response.data.inUse;
      if (isInUse) {
        setDialogTitle('Action Not Allowed');
        setConfirmMessage('Department is in use by a program and cannot be deleted.');
        setConfirmDelete(true);
        return;
      }

      setDepartmentToDelete(department);
      setDialogTitle('Confirm Deletion');
      setConfirmMessage('Are you sure you want to delete this department? This action cannot be undone.');
      setConfirmDelete(true);
    } catch (error) {
      console.error('Failed to check if department is in use:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await departmentService.deleteDepartment(departmentToDelete.deptid);
      setConfirmDelete(false);
      setDepartmentToDelete(null);
      fetchDepartments();
    } catch (error) {
      setConfirmMessage(error.response.data || 'Failed to delete the department.');
    }
  };

  const toggleForm = () => {
    setShowForm(!showForm);
    if (!showForm) {
      setFormData({ deptid: null, deptname: '', deptdescription: '' });
      setFormError('');
    }
  };

  const validateDes = (Des) => {
    const formattedDes = formatDeptDescription(Des);
    const DesRegex = /^(?!.*\d)([A-Z]+[ ,.!?;:()/"'-]*)+[.]$/;
    return DesRegex.test(formattedDes);
  };

  const validateName = (name) => {
    const formattedName = formatDeptName(name);
    const NameRegex = /^[A-Z]+(?:[ ][A-Z]+)*$/;
    return NameRegex.test(formattedName);
  };

  const isFormValid = () => {
    return formData.deptname.trim() && formData.deptdescription.trim() && validateName(formData.deptname) && validateDes(formData.deptdescription);
  };

  return (
    <Box display="flex" justifyContent="center" sx={{ mt: 4, mb: 4, ml: matches ? '280px' : 0, width: matches ? `calc(100% - 280px)` : '100%' }}>
      <Container maxWidth="lg">
        <Typography variant="h4" sx={{ mb: 3 }}>Departments</Typography>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button
            startIcon={showForm ? <Close /> : <Add />}
            variant="contained"
            style={{ backgroundColor: '#6c63ff', color: 'white' }}
            onClick={toggleForm}
            sx={{ borderRadius: 2, boxShadow: '0 3px 5px 2px rgba(105, 140, 255, .3)' }}
          >
            {showForm ? 'Cancel' : 'Add Department'}
          </Button>
        </Box>
        {showForm && (
          <Card elevation={12} sx={{ mb: 3, p: 2, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>{formData.deptid ? 'Edit Department' : 'Add New Department'}</Typography>
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                  name="deptname"
                  label="Department Name"
                  value={formData.deptname}
                  onChange={handleChange}
                  required
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  error={formData.deptname && !validateName(formData.deptname)}
                  helperText={formData.deptname && !validateName(formData.deptname) && 'Only Use English Alphabets & Spaces'}
                />
                <TextField
                  name="deptdescription"
                  label="Description"
                  value={formData.deptdescription}
                  onChange={handleChange}
                  required
                  fullWidth
                  multiline
                  rows={4}
                  InputLabelProps={{ shrink: true }}
                  error={formData.deptdescription && !validateDes(formData.deptdescription)}
                  helperText={formData.deptdescription && !validateDes(formData.deptdescription) && 'Only Use English Alphabets, Spaces and Allowed Punctuation: " \' . , ! ? ; : ( ) / -'}
                />
                <Button type="submit" variant="contained" style={{ backgroundColor: '#6c63ff', color: 'white' }} sx={{ mt: 2 }} disabled={!isFormValid()}>
                  {formData.deptid ? 'Update Department' : 'Create Department'}
                </Button>
                {formError && <Typography color="error" sx={{ mt: 2 }}>{formError}</Typography>}
              </Box>
            </CardContent>
          </Card>
        )}
        <TableContainer component={Paper} elevation={12} sx={{
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          border: '1px solid rgba(0,0,0,0.12)'
        }}>
          <Table aria-label="departments table">
            <TableHead sx={{ bgcolor: '#e0e0e0' }}>
              <TableRow sx={{ '& th': { fontWeight: 'bold' } }}>
                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Sr. No.</TableCell>
                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Department Name</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {departments.map((dept, index) => (
                <TableRow key={dept.deptid}>
                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{index + 1}</TableCell>
                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)', whiteSpace: 'nowrap' }}>{dept.deptname}</TableCell>
                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{dept.deptdescription}</TableCell>
                  <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                    <IconButton color="primary" onClick={() => handleEdit(dept)}>
                      <Edit />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleDeleteClick(dept)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog
          open={confirmDelete}
          onClose={() => setConfirmDelete(false)}
        >
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {confirmMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDelete(false)} color="primary">
              Cancel
            </Button>
            {!confirmMessage.includes('in use') && (
              <Button onClick={handleDelete} color="primary">
                Confirm
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

function formatDeptName(name) {
  let formattedName = name.trim();
  formattedName = formattedName.replace(/\s+/g, ' ');
  return formattedName.toUpperCase();
}

function formatDeptDescription(description) {
  let formattedDescription = description.trim();
  formattedDescription = formattedDescription.replace(/\s+/g, ' ');

  if (!formattedDescription.endsWith('.')) {
    formattedDescription += '.';
  }

  return formattedDescription.toUpperCase();
}

export default DepartmentPage;
