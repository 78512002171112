
import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const login = (id, password, role) => {
  return axios.post(`${baseURL}/login`, { id, password, role });
};

const authService = {
  login
};

export default authService;
