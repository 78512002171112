import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const addTeacher = (teacherData) => {
    return axios.post(`${baseURL}/teachers`, teacherData);
};

const getTeachers = () => {
    return axios.get(`${baseURL}/teachers`);
};

const markTeacherLeft = (teachid) => {
    return axios.patch(`${baseURL}/teachers/markleft/${teachid}`);
};

const isTeacherInUse = (teachid) => {
    return axios.get(`${baseURL}/teachers/useby/${teachid}`);
};

const updateTeacher = (teachid, data) => {
    return axios.put(`${baseURL}/teachers/update/${teachid}`, data);
};

const deleteTeacher = (teachid) => {
    return axios.delete(`${baseURL}/teachers/delete/${teachid}`);
};

const teacherAPI = {
    addTeacher,
    getTeachers,
    isTeacherInUse,
    updateTeacher,
    deleteTeacher,
    markTeacherLeft
};

export default teacherAPI;
