import React, { createContext, useContext, useEffect, useState } from 'react';

const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const [locationState, setLocationState] = useState(() => {
    const storedLocationState = localStorage.getItem('locationState');
    return storedLocationState ? JSON.parse(storedLocationState) : null;
  });

  useEffect(() => {
    localStorage.setItem('locationState', JSON.stringify(locationState));
  }, [locationState]);

  return (
    <LocationContext.Provider value={{ locationState, setLocationState }}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationState = () => useContext(LocationContext);
