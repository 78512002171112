import React, { useEffect, useState } from 'react';
import { Grid, Card, Typography, CircularProgress, Box, Select, MenuItem, FormControl, InputLabel, useMediaQuery, useTheme } from '@mui/material';
import { BarChart, Cell, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import SchoolIcon from '@mui/icons-material/School';
import ChecklistIcon from "@mui/icons-material/ChecklistRtl";
import RuleIcon from "@mui/icons-material/Rule";
import { useLocationState } from '../context/LocationContext';
import homeService from '../api/homeService';

function StudentHomePage() {
    const [stats, setStats] = useState({ completed_courses: 0, not_completed_courses: 0 });
    const [loading, setLoading] = useState(true);
    const [courseData, setCourseData] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState('');
    const [courses, setCourses] = useState([]);
    const { locationState } = useLocationState();
    const [programData, setProgramData] = useState([]);
    const [selectedBatch, setSelectedBatch] = useState('');
    const userId = locationState?.userId;

    useEffect(() => {
        async function fetchStats() {
            try {
                const response = await homeService.getStudentStats(userId);
                setStats(response.data);

                const courseResponse = await homeService.getStudentCourseAttainmentStats(userId);
                const transformedCourseData = transformData(courseResponse.data);
                setCourseData(transformedCourseData);
                setCourses([...new Set(courseResponse.data.map(item => item.assignedcrsid))].sort());
                setSelectedCourse(courseResponse.data[0]?.assignedcrsid || '');

                const programResponse = await homeService.getStudentProgramAttainmentStats(userId);
                setProgramData(transformData(programResponse.data));
                setSelectedBatch(programResponse.data[0]?.batchid || '');
            } catch (error) {
                console.error('Failed to fetch statistics', error);
            } finally {
                setLoading(false);
            }
        }
        fetchStats();
    }, [userId]);

    const transformData = (data) => {
        const dataMap = {};
        data.forEach(item => {
            const key = item.batchid || item.assignedcrsid;
            if (!dataMap[key]) {
                dataMap[key] = { CLO: [], PLO: [] };
            }
            const entry = {
                id: item.id,
                attainment: item.attainment,
                status: item.status === 'Pass' ? 'Passed' : 'Failed'
            };
            if (item.type === 'CLO') {
                dataMap[key].CLO.push(entry);
            } else {
                dataMap[key].PLO.push(entry);
            }
        });
        return Object.keys(dataMap).sort().reduce((acc, key) => {
            acc[key] = dataMap[key];
            return acc;
        }, {});
    };

    const handleCourseChange = (event) => {
        setSelectedCourse(event.target.value);
    };

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    function EnhancedBarChart({ data, dataKey }) {
        const maxBarWidth = 60;
        const barSize = Math.min(maxBarWidth, 300 / data.length);

        const gradientColors = [
            { start: theme.palette.primary.light, end: theme.palette.primary.dark },
            { start: theme.palette.secondary.light, end: theme.palette.secondary.dark },
        ];

        return (
            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <defs>
                        {gradientColors.map((color, index) => (
                            <linearGradient id={`colorUv${index}`} key={index} x1="0" y1="0" x2="0" y2="1">
                                <stop offset="10%" stopColor={color.start} stopOpacity={0.8} />
                                <stop offset="90%" stopColor={color.end} stopOpacity={1} />
                            </linearGradient>
                        ))}
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="id" tick={{ fill: theme.palette.text.primary }} />
                    <YAxis tick={{ fill: theme.palette.text.primary }} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Bar dataKey={dataKey} barSize={barSize} radius={[10, 10, 0, 0]}>
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={`url(#colorUv${index % gradientColors.length})`} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        );
    }

    if (loading) {
        return <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}><CircularProgress /></Grid>;
    }

    return (
        <Box display="flex" justifyContent="center" sx={{ mt: 4, mb: 4, ml: matches ? '300px' : '20px', mr: matches ? '20px' : '20px', width: matches ? `calc(100% - 320px)` : 'calc(100% - 40px)' }}>
            <Grid container spacing={3}>
                {Object.keys(stats).map((key, index) => (
                    <Grid item xs={12} sm={6} md={6} key={index}>
                    <StatCard
                      title={getTitle(key)}
                      value={stats[key]}
                      icon={getIcon(key)}
                      colorIndex={index}
                    />
                  </Grid>
                ))}
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="course-selector-label">Select a Course</InputLabel>
                        <Select
                            labelId="course-selector-label"
                            id="course-selector"
                            value={selectedCourse}
                            label="Select a Course"
                            onChange={handleCourseChange}
                            style={{ marginBottom: 20 }}
                        >
                            {courses.map(course => <MenuItem key={course} value={course}>{course}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">CLO Attainment Overview</Typography>
                    <EnhancedBarChart data={courseData[selectedCourse]?.CLO || []} dataKey="attainment" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">PLO Attainment Overview</Typography>
                    <EnhancedBarChart data={courseData[selectedCourse]?.PLO || []} dataKey="attainment" />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h6">Program-Wise PLO Attainment Overview</Typography>
                    <EnhancedBarChart data={programData[selectedBatch]?.PLO || []} dataKey="attainment" />
                </Grid>
            </Grid>
        </Box>
    );
}

function getIcon(key) {
    switch (key) {
        case 'completed_courses': return <ChecklistIcon />;
        case 'not_completed_courses': return <RuleIcon />;
        default: return <SchoolIcon />;
    }
}

function getTitle(key) {
    switch (key) {
        case 'completed_courses': return 'Completed Courses';
        case 'not_completed_courses': return 'Courses in Progress';
        default: return '';
    }
}

function StatCard({ title, value, icon, colorIndex }) {
    const theme = useTheme();
  
    const gradientColors = [
      { start: theme.palette.primary.light, end: theme.palette.primary.dark },
      { start: theme.palette.secondary.light, end: theme.palette.secondary.dark },
    ];
  
    const background = `linear-gradient(145deg, ${
      gradientColors[colorIndex % gradientColors.length].start
    }, ${gradientColors[colorIndex % gradientColors.length].end})`;
  
    return (
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: 2,
          height: "auto",
          minWidth: "350px",
          background: background,
          color: "#fff",
          boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
          "&:hover": {
            boxShadow: "0 6px 24px 0 rgba(0,0,0,0.18)",
            transform: "translateY(-5px)",
            transition: "all 0.3s ease-out",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 1,
          }}
        >
          <Typography variant="h6" component="div" sx={{ fontWeight: "medium" }}>
            {title}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "2.5rem",
              animation: "float 3s ease-in-out infinite",
            }}
          >
            {icon}
            <Typography
              variant="h4"
              component="div"
              sx={{ fontWeight: "bold", marginLeft: 2 }}
            >
              {value}
            </Typography>
          </Box>
        </Box>
      </Card>
    );
  }

function CustomTooltip({ active, payload, label }) {
    if (active && payload && payload.length) {
        return (
            <Card sx={{ p: 2, backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                <Typography variant="subtitle1" color="text.primary">{label}</Typography>
                <Typography variant="body2" color="text.secondary">
                    {`Attainment: ${payload[0].value.toFixed(2)}% - ${payload[0].payload.status}`}
                </Typography>
            </Card>
        );
    }

    return null;
}

export default StudentHomePage;
