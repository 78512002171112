import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const updateTeacherPassword = (teachid, newPassword) => {
    return axios.put(`${baseURL}/teachers/update-password/${teachid}`, { newPassword });
};

const updateStudentPassword = (stuid, newPassword) => {
    return axios.put(`${baseURL}/students/update-password/${stuid}`, { newPassword });
};

const updateAdminPassword = (adminid, newPassword) => {
    return axios.put(`${baseURL}/admin/update-password/${adminid}`, { newPassword });
};

const updatePassAPI = {
    updateTeacherPassword,
    updateStudentPassword,
    updateAdminPassword
};

export default updatePassAPI;
