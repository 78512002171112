import React, { useState, useEffect } from 'react';
import {
  Container, Typography, Button, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, Collapse, Box, useMediaQuery, useTheme
} from '@mui/material';
import taxonomyDomainService from '../api/taxonomyDomainService';

const TaxonomyDomainPage = () => {
  const [taxonomyDomains, setTaxonomyDomains] = useState([]);
  const [open, setOpen] = useState({});

  useEffect(() => {
    fetchTaxonomyDomains();
  }, []);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const fetchTaxonomyDomains = async () => {
    try {
      const response = await taxonomyDomainService.getTaxonomyDomains();
      setTaxonomyDomains(response.data);
    } catch (error) {
      console.error('Failed to fetch taxonomy domains:', error);
    }
  };

  const toggleOpen = (id) => {
    setOpen(prev => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <Box display="flex" justifyContent="center" sx={{ mt: 4, mb: 4, ml: matches ? '280px' : 0, width: matches ? `calc(100% - 280px)` : '100%' }}>
    <Container maxWidth="lg">
      <Typography variant="h4" sx={{ mb: 3 }}>Taxonomy Domains</Typography>
      <TableContainer component={Paper} elevation={12} sx={{
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          border: '1px solid rgba(0,0,0,0.12)'
        }}>
        <Table aria-label="taxonomy domains table">
        <TableHead sx={{ bgcolor: '#e0e0e0' }}>
        <TableRow sx={{ '& th': { fontWeight: 'bold' } }}>
              <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Sr. No.</TableCell>
              <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Domain Name</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">Levels</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taxonomyDomains.map((domain, index) => (
              <React.Fragment key={domain.domainid}>
                <TableRow>
                <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{index + 1}</TableCell>
                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)', whiteSpace: 'nowrap' }}>{domain.domainname}</TableCell>
                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{domain.domaindescription}</TableCell>
                  <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                    <Button onClick={() => toggleOpen(domain.domainid)}>
                      {open[domain.domainid] ? 'Hide Levels' : 'View Levels'}
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={open[domain.domainid]} timeout="auto" unmountOnExit>
                      <Box sx={{ margin: 1 }}>
                        <Typography variant="h6" gutterBottom component="div">
                          Levels
                        </Typography>
                        <Table size="small" aria-label="levels">
                          <TableHead>
                          <TableRow sx={{ '& th': { fontWeight: 'bold' } }}>
                              <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Level ID</TableCell>
                              <TableCell align="center">Description</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {domain.levels.map((level) => (
                              <TableRow key={level.domainlevel}>
                                <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{level.domainlevel}</TableCell>
                                <TableCell align="center">{level.levelname}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
    </Box>
  );
};

export default TaxonomyDomainPage;
