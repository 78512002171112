import React, { useState } from 'react';
import {
  Container, Typography, Button, TextField, Box, Card, CardContent, IconButton, InputAdornment, useMediaQuery, useTheme
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import batchService from '../api/updatePasswordService';
import { useLocationState } from '../context/LocationContext';

const UpdatePasswordPage = () => {
  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: ''
  });
  const [formError, setFormError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { locationState } = useLocationState();
  const userId = locationState?.userId;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const handleChange = (e) => {
    if (formError) {
      setFormError('');
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.newPassword !== formData.confirmPassword) {
      setFormError('Passwords do not match');
      return;
    }
    if (!validatePassword(formData.newPassword)) {
      setFormError('Password must be at least 8 characters long and include a number and a special character');
      return;
    }
    try {
      await batchService.updateStudentPassword(userId, formData.newPassword);
      setFormData({ newPassword: '', confirmPassword: '' });
      setShowPassword(false);
      setShowConfirmPassword(false);
    } catch (error) {
      setFormError(error.response.data || 'Failed to update password');
    }
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    return passwordRegex.test(password);
  };

  const allFieldsFilled = Object.values(formData).every(val => val) && validatePassword(formData.newPassword);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Box display="flex" justifyContent="center" sx={{ mt: 4, mb: 4, ml: matches ? '280px' : 0, width: matches ? `calc(100% - 280px)` : '100%' }}>
      <Container maxWidth="sm">
        <Typography variant="h4" sx={{ mb: 3 }}>Update Password</Typography>
        <Card elevation={12} sx={{ mb: 3, p: 2, borderRadius: 2 }}>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 2 }}>Enter New Password</Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                name="newPassword"
                label="New Password"
                type={showPassword ? 'text' : 'password'}
                value={formData.newPassword}
                onChange={handleChange}
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={formData.newPassword && !validatePassword(formData.newPassword)}
                helperText={formData.newPassword && !validatePassword(formData.newPassword) && 'Password must be at least 8 characters long and include a number and a special character'}
              />
              <TextField
                name="confirmPassword"
                label="Confirm Password"
                type={showConfirmPassword ? 'text' : 'password'}
                value={formData.confirmPassword}
                onChange={handleChange}
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleConfirmPasswordVisibility}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Button type="submit" variant="contained" disabled={!allFieldsFilled} sx={{ mt: 2 }} style={{ backgroundColor: '#6c63ff', color: 'white' }}>
                Update Password
              </Button>
              {formError && (
                <Typography color="error" sx={{ mt: 2 }}>{formError}</Typography>
              )}
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default UpdatePasswordPage;
