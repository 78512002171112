import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, InputAdornment, TextField, Button, Typography, Box, Paper, useTheme, FormHelperText, MenuItem } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useUser } from '../context/UserContext';
import { useLocationState } from '../context/LocationContext';
import authService from '../api/authService';

const LoginForm = () => {
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('administrator');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const { login } = useUser();
  const { setLocationState } = useLocationState();
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleInputChange = (setter) => (e) => {
    setError('');
    setter(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    if (!id || !password) {
      setError('Please fill in all required fields.');
      return;
    }

    try {
      const response = await authService.login(id, password, role);

      if (response && response.data) {
        const userData = { ...response.data, id, role, token: response.data.token };
        setLocationState({ userId: userData.id });
        login(userData);
        navigate(`/${role}`);
      } else {
        setError('Login failed: Unexpected response from server');
      }
    } catch (error) {
      setError('Login failed: ' + (error.response?.data || error.message));
    }
  };

  const isFormValid = id && password;

  return (
    <Paper elevation={12} sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      background: theme.palette.background.paper,
      boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
      width: '100%',
      maxWidth: 400,
    }}>
      <Typography component="h1" variant="h4" sx={{ mb: 3, color: 'black' }}>
        Sign in
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="id"
          label="User ID"
          name="id"
          autoFocus
          placeholder="Enter your User ID"
          value={id}
          onChange={handleInputChange(setId)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
            style: { backgroundColor: 'white' } 
          }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          id="password"
          autoComplete="off" // Disable autofill
          placeholder="Enter your Password"
          value={password}
          onChange={handleInputChange(setPassword)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            style: { backgroundColor: 'white' } 
          }}
        />
        <TextField
          select
          label="Role"
          fullWidth
          value={role}
          onChange={handleInputChange(setRole)}
          SelectProps={{ native: false }}
          variant="outlined"
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircleIcon />
              </InputAdornment>
            ),
            style: { backgroundColor: 'white' } 
          }}
        >
          <MenuItem value="administrator">Administrator</MenuItem>
          <MenuItem value="student">Student</MenuItem>
          <MenuItem value="teacher">Teacher</MenuItem>
        </TextField>
        {error && (
          <FormHelperText error>{error}</FormHelperText>
        )}
        <Button
          type="submit"
          variant="contained"
          style={{ backgroundColor: '#6c63ff', color: 'white' }}
          sx={{
            mt: 3,
            mb: 2,
            py: 1.5,
            borderRadius: 20,
            color: 'white',
            '&:hover': {
              backgroundColor: '#5a54d6',
            }
          }}
          disabled={!isFormValid}
          fullWidth
        >
          Sign In
        </Button>
      </Box>
    </Paper>
  );
};

export default LoginForm;