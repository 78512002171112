import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const fetchCourseAttainmentData = (assignedcrsid) => {
    return axios.get(`${baseURL}/crswisecloploattainment/${assignedcrsid}`);
};

const getCourseStudents = (assignedcrsid) => {
    return axios.get(`${baseURL}/crswisecloploattainment/course/students/${assignedcrsid}`);
};

const getCompleteAssignedCourses = () => {
    return axios.get(`${baseURL}/crswisecloploattainment/assignedcourses/complete`);
};

const getCompleteCoursesByTeacher = (teachid) => {
    return axios.get(`${baseURL}/crswisecloploattainment/assignedcourses/teacher/complete/${teachid}`);
};

const getCompleteCoursesByStudent = (stuid) => {
    return axios.get(`${baseURL}/crswisecloploattainment/assignedcourses/student/complete/${stuid}`);
};

const fetchCourseAttainmentDataForStudent = (assignedcrsid, stuid) => {
    return axios.get(`${baseURL}/crswisecloploattainment/${assignedcrsid}/${stuid}`);
};

const fetchProgramAttainmentData = (batchid) => {
    return axios.get(`${baseURL}/programwiseploattainment/${batchid}`);
};

const getStudentBatch = (stuid) => {
    return axios.get(`${baseURL}/programwiseploattainment/batches/student/${stuid}`);
};

const fetchProgramAttainmentDataForStudent = (batchid, stuid) => {
    return axios.get(`${baseURL}/programwiseploattainment/${batchid}/${stuid}`);
};

const attainmentService = {
    fetchCourseAttainmentData,
    getCourseStudents,
    getCompleteAssignedCourses,
    getCompleteCoursesByTeacher,
    getCompleteCoursesByStudent,
    fetchCourseAttainmentDataForStudent,
    fetchProgramAttainmentData,
    getStudentBatch,
    fetchProgramAttainmentDataForStudent
};

export default attainmentService;
