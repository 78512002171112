import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const createProgram = (programData) => {
  return axios.post(`${baseURL}/programs`, programData);
};

const getPrograms = () => {
  return axios.get(`${baseURL}/programs`);
};

const updateProgram = (progid, data) => {
  return axios.put(`${baseURL}/programs/update/${progid}`, data);
};

const deleteProgram = (progid) => {
  return axios.delete(`${baseURL}/programs/delete/${progid}`);
};

const isProgramInUse = (progid) => {
  return axios.get(`${baseURL}/programs/usebybatch/${progid}`);
};

const programAPI = {
  createProgram,
  getPrograms,
  updateProgram,
  deleteProgram,
  isProgramInUse
};

export default programAPI;
