import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const fetchCqiSuggestion = (assignedcrsid) => {
    return axios.get(`${baseURL}/cqisuggestion/${assignedcrsid}`);
};

const fetchStudentImprovementSuggestion = (assignedcrsid, stuid) => {
    return axios.get(`${baseURL}/cqisuggestion/improvement/${assignedcrsid}/${stuid}`);
};

const suggestionService = {
    fetchCqiSuggestion,
    fetchStudentImprovementSuggestion
};

export default suggestionService;
