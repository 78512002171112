import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const getAdminStats = () => {
    return axios.get(`${baseURL}/admin-stats`);
};

const getTeacherStats = (teachid) => {
    return axios.get(`${baseURL}/teacher-stats/${teachid}`);
};

const getStudentStats = (stuid) => {
    return axios.get(`${baseURL}/student-stats/${stuid}`);
};

const getCourseAttainmentStats = () => {
    return axios.get(`${baseURL}/course-attainment-stats`);
}

const getTeacherCourseAttainmentStats = (teachid) => {
    return axios.get(`${baseURL}/teacher-course-attainment-stats/${teachid}`);
}

const getStudentCourseAttainmentStats = (stuid) => {
    return axios.get(`${baseURL}/student-course-attainment-stats/${stuid}`);
}

const getProgramAttainmentStats = () => {
    return axios.get(`${baseURL}/program-attainment-stats`);
}

const getStudentProgramAttainmentStats = (stuid) => {
    return axios.get(`${baseURL}/student-program-attainment-stats/${stuid}`);
}

const homeAPI = {
    getAdminStats,
    getCourseAttainmentStats,
    getProgramAttainmentStats,
    getTeacherStats,
    getTeacherCourseAttainmentStats,
    getStudentStats,
    getStudentCourseAttainmentStats,
    getStudentProgramAttainmentStats
};

export default homeAPI;
