import React, { useState, useEffect } from 'react';
import {
    Container, Typography, TextField, MenuItem, Card, CardContent, CircularProgress, useMediaQuery, useTheme, Box
} from '@mui/material';
import cqiService from '../api/suggestionService';
import attainmentService from '../api/attainmentService';

const CqiSuggestionPage = () => {
    const [selectedCourseId, setSelectedCourseId] = useState('');
    const [courses, setCourses] = useState([]);
    const [suggestions, setSuggestions] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const { data } = await attainmentService.getCompleteAssignedCourses();
                setCourses(data || []);
            } catch (error) {
                console.error('Error fetching courses:', error);
            }
        };

        fetchCourses();
    }, []);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const handleCourseChange = async (event) => {
        const courseId = event.target.value;
        setSelectedCourseId(courseId);
        setLoading(true);
        setError('');

        try {
            const response = await cqiService.fetchCqiSuggestion(courseId);
            if (response.data.error) {
                setError(response.data.error);
                setSuggestions('');
            } else {
                setSuggestions(response.data); // Set the full HTML content
                setError('');
            }
        } catch (error) {
            setError('Failed to fetch CQI suggestion.');
        }

        setLoading(false);
    };

    return (
        <Box display="flex" justifyContent="center" sx={{ mt: 4, mb: 4, ml: matches ? '280px' : 0, width: matches ? `calc(100% - 280px)` : '100%' }}>
            <Container maxWidth="lg">
                <Typography variant="h4" sx={{ mb: 3 }}>CQI Suggestion</Typography>
                <TextField select name="assignedcrsid" label="Assigned Course" value={selectedCourseId} onChange={handleCourseChange} sx={{ mb: 2 }} required fullWidth InputLabelProps={{ shrink: true }}>
                    {courses.map((course) => (
                        <MenuItem key={course.assignedcrsid} value={course.assignedcrsid}>
                            {course.assignedcrsid}
                        </MenuItem>
                    ))}
                </TextField>
                {error && <Typography color="error">{error}</Typography>}
                {loading ? (
                    <CircularProgress />
                ) : (
                    suggestions && (
                        <Card elevation={12} sx={{ mb: 3, p: 2, borderRadius: 2 }}>
                            <CardContent>
                                <div dangerouslySetInnerHTML={{ __html: suggestions }} />
                            </CardContent>
                        </Card>
                    )
                )}
            </Container>
        </Box>
    );
};

export default CqiSuggestionPage;
