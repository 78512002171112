import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { UserProvider, useUser } from './context/UserContext';
import { LocationProvider } from './context/LocationContext';
import ProtectedRoute from './components/ProtectedRoute';
import LoginPage from './pages/LoginPage';
import AdminHome from './pages/AdminHome';
import StudentHome from './pages/StudentHome';
import TeacherHome from './pages/TeacherHome';
import DepartmentPage from './pages/DepartmentPage';
import ProgramPage from './pages/ProgramPage';
import BatchPage from './pages/BatchPage';
import TeacherPage from './pages/TeacherPage';
import CoursePage from './pages/CoursePage';
import AssignCoursePage from './pages/AssignCoursePage';
import TaxonomyDomainPage from './pages/TaxonomyDomainPage';
import CourseAttainmentPage from './pages/CourseAttainmentPage';
import ProgramAttainmentPage from './pages/ProgramAttainmentPage';
import CqiSuggestionPage from './pages/CqiSuggestionPage';
import UpdatePasswordPage from './pages/UpdatePasswordPage';
import StudentCourseAttainmentPage from './pages/StudentCourseAttainmentPage';
import StudentProgramAttainmentPage from './pages/StudentProgramAttainmentPage';
import StudentImprovementSuggestionPage from './pages/StudentImprovementSuggestionPage';
import StudentUpdatePasswordPage from './pages/StudentUpdatePasswordPage';
import AssessmentWeightagePage from './pages/AssessmentWeightagePage';
import CloEntryPage from './pages/CloEntryPage';
import MarkEntryPage from './pages/MarkEntryPage';
import TeacherCourseAttainmentPage from './pages/TeacherCourseAttainmentPage';
import TeacherCqiSuggestionPage from './pages/TeacherCqiSuggestionPage';
import TeacherUpdatePasswordPage from './pages/TeacherUpdatePasswordPage';
import NavBar from './components/NavBar';

const App = () => (
  <UserProvider>
    <LocationProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Navigate to="/login" />} />
            <Route path="administrator" element={<ProtectedRoute role="administrator"><AdminHome /></ProtectedRoute>} />
            <Route path="administrator/departments" element={<ProtectedRoute role="administrator"><DepartmentPage /></ProtectedRoute>} />
            <Route path="administrator/programs" element={<ProtectedRoute role="administrator"><ProgramPage /></ProtectedRoute>} />
            <Route path="administrator/batches" element={<ProtectedRoute role="administrator"><BatchPage /></ProtectedRoute>} />
            <Route path="administrator/teachers" element={<ProtectedRoute role="administrator"><TeacherPage /></ProtectedRoute>} />
            <Route path="administrator/courses" element={<ProtectedRoute role="administrator"><CoursePage /></ProtectedRoute>} />
            <Route path="administrator/assignedcourses" element={<ProtectedRoute role="administrator"><AssignCoursePage /></ProtectedRoute>} />
            <Route path="administrator/taxonomydomains" element={<ProtectedRoute role="administrator"><TaxonomyDomainPage /></ProtectedRoute>} />
            <Route path="administrator/courseattainment" element={<ProtectedRoute role="administrator"><CourseAttainmentPage /></ProtectedRoute>} />
            <Route path="administrator/programattainment" element={<ProtectedRoute role="administrator"><ProgramAttainmentPage /></ProtectedRoute>} />
            <Route path="administrator/cqisuggestion" element={<ProtectedRoute role="administrator"><CqiSuggestionPage /></ProtectedRoute>} />
            <Route path="administrator/updatepassword" element={<ProtectedRoute role="administrator"><UpdatePasswordPage /></ProtectedRoute>} />
            <Route path="student" element={<ProtectedRoute role="student"><StudentHome /></ProtectedRoute>} />
            <Route path="student/courseattainment" element={<ProtectedRoute role="student"><StudentCourseAttainmentPage /></ProtectedRoute>} />
            <Route path="student/programattainment" element={<ProtectedRoute role="student"><StudentProgramAttainmentPage /></ProtectedRoute>} />
            <Route path="student/improvementsuggestion" element={<ProtectedRoute role="student"><StudentImprovementSuggestionPage /></ProtectedRoute>} />
            <Route path="student/updatepassword" element={<ProtectedRoute role="student"><StudentUpdatePasswordPage /></ProtectedRoute>} />
            <Route path="teacher" element={<ProtectedRoute role="teacher"><TeacherHome /></ProtectedRoute>} />
            <Route path="teacher/assessmentweightages" element={<ProtectedRoute role="teacher"><AssessmentWeightagePage /></ProtectedRoute>} />
            <Route path="teacher/closentry" element={<ProtectedRoute role="teacher"><CloEntryPage /></ProtectedRoute>} />
            <Route path="teacher/marksentry" element={<ProtectedRoute role="teacher"><MarkEntryPage /></ProtectedRoute>} />
            <Route path="teacher/courseattainment" element={<ProtectedRoute role="teacher"><TeacherCourseAttainmentPage /></ProtectedRoute>} />
            <Route path="teacher/cqisuggestion" element={<ProtectedRoute role="teacher"><TeacherCqiSuggestionPage /></ProtectedRoute>} />
            <Route path="teacher/updatepassword" element={<ProtectedRoute role="teacher"><TeacherUpdatePasswordPage /></ProtectedRoute>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </LocationProvider>
  </UserProvider>
);

const MainLayout = () => {
  const { user } = useUser();

  return (
    <>
      {user ? <NavBar /> : <Navigate to="/login" />}
      <Outlet />
    </>
  );
};

export default App;
