import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  TextField,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  MenuItem,
  Collapse,
  useMediaQuery,
  useTheme,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { Add, Close, Edit, Delete } from '@mui/icons-material';
import programService from '../api/programService';
import departmentService from '../api/departmentService';

const ProgramPage = () => {
  const [programs, setPrograms] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [formError, setFormError] = useState('');
  const [open, setOpen] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('');
  const [programToDelete, setProgramToDelete] = useState(null);
  const [formData, setFormData] = useState({
    progid: null,
    progname: '',
    progch: '',
    deptid: '',
    plosCount: '',
    plos: []
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [deptResponse, progResponse] = await Promise.all([
          departmentService.getDepartments(),
          programService.getPrograms()
        ]);
        const departments = deptResponse.data.map(dept => ({ value: dept.deptid, label: dept.deptname }));
        const programs = progResponse.data.sort((a, b) => {
          const deptA = departments.find(dept => dept.value === a.deptid)?.label || '';
          const deptB = departments.find(dept => dept.value === b.deptid)?.label || '';

          if (deptA !== deptB) {
            return deptA.localeCompare(deptB);
          }

          return a.progname.localeCompare(b.progname);
        });

        setDepartments(departments);
        setPrograms(programs);
      } catch (error) {
        console.error('Failed to fetch initial data:', error);
      }
    };

    fetchData();
  }, []);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const fetchPrograms = async () => {
    try {
      const response = await programService.getPrograms();
      const sortedPrograms = response.data.sort((a, b) => {
        const deptA = departments.find(dept => dept.value === a.deptid)?.label || '';
        const deptB = departments.find(dept => dept.value === b.deptid)?.label || '';

        if (deptA !== deptB) {
          return deptA.localeCompare(deptB);
        }

        return a.progname.localeCompare(b.progname);
      });
      setPrograms(sortedPrograms);
    } catch (error) {
      console.error('Failed to fetch programs:', error);
    }
  };

  const handleChange = (e) => {
    if (formError) {
      setFormError('');
    }

    const { name, value } = e.target;

    if (name === 'plosCount') {
      const count = Math.max(0, Math.min(20, Number(value)));
      const plos = Array(count).fill().map((_, index) => ({
        ploid: `PLO${index + 1}`,
        plodescription: formData.plos[index] ? formData.plos[index].plodescription : ''
      }));
      setFormData({ ...formData, plos, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handlePLOChange = (index, field, value) => {
    if (formError) {
      setFormError('');
    }
    const updatedPLOs = formData.plos.map((plo, i) => {
      if (i === index) return { ...plo, [field]: value };
      return plo;
    });

    setFormData({ ...formData, plos: updatedPLOs });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.plos.length) {
      setFormError('At least one PLO must be added.');
      return;
    }

    const formattedProgName = formatInput(formData.progname);
    const formattedPLOs = formData.plos.map(plo => ({
      ...plo,
      plodescription: formatInput(plo.plodescription)
    }));
    const updatedFormData = {
      ...formData,
      progname: formattedProgName,
      plos: formattedPLOs,
      progch: parseInt(formData.progch)
    };

    try {
      if (formData.progid) {
        await programService.updateProgram(formData.progid, updatedFormData);
      } else {
        await programService.createProgram(updatedFormData);
      }
      setFormData({
        progid: null, progname: '', progch: '', deptid: '', plosCount: '', plos: []
      });
      setFormError('');
      setShowForm(false);
      fetchPrograms();
    } catch (error) {
      setFormError(error.response.data);
    }
  };

  const handleEdit = async (program) => {
    if (formError) {
      setFormError('');
    }
    try {
      const response = await programService.isProgramInUse(program.progid);
      const isInUse = response.data.inUse;
      if (isInUse) {
        setDialogTitle('Action Not Allowed');
        setConfirmMessage('Program is in use by a batch and cannot be edited.');
        setConfirmDelete(true);
        return;
      }
    } catch (error) {
      console.error('Failed to check if program is in use:', error);
    }

    setFormData({
      progid: program.progid,
      progname: program.progname,
      progch: program.progch,
      deptid: program.deptid,
      plosCount: program.plos.length,
      plos: program.plos
    });
    setShowForm(true);
  };

  const handleDeleteClick = async (program) => {
    try {
      const response = await programService.isProgramInUse(program.progid);
      const isInUse = response.data.inUse;
      if (isInUse) {
        setDialogTitle('Action Not Allowed');
        setConfirmMessage('Program is in use by a batch and cannot be deleted.');
        setConfirmDelete(true);
        return;
      }
    } catch (error) {
      console.error('Failed to check if program is in use:', error);
    }

    setProgramToDelete(program);
    setDialogTitle('Confirm Deletion');
    setConfirmMessage('Are you sure you want to delete this program? This action cannot be undone.');
    setConfirmDelete(true);
  };

  const handleDelete = async () => {
    try {
      await programService.deleteProgram(programToDelete.progid);
      setConfirmDelete(false);
      setProgramToDelete(null);
      fetchPrograms();
    } catch (error) {
      setConfirmMessage(error.response.data || 'Failed to delete the program.');
    }
  };

  const toggleForm = () => {
    setShowForm(!showForm);

    if (!showForm) {
      setFormData({
        progid: null, progname: '', progch: '', deptid: '', plosCount: '', plos: []
      });
      setFormError('');
    }
  };

  const validateCH = (CH) => {
    return CH >= 1 && CH <= 150;
  };

  const validateDes = (Des) => {
    const formattedDes = formatInput(Des);
    const DesRegex = /^(?!.*\d)([A-Z]+[ ,.!?;:()/"'-]*)+$/;
    return DesRegex.test(formattedDes);
  };

  const validateName = (name) => {
    const formattedName = formatInput(name);
    const NameRegex = /^[A-Z]+(?:[ ][A-Z]+)*$/;
    return NameRegex.test(formattedName);
  };

  const toggleOpen = (id) => {
    setOpen(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const allFieldsFilled = formData.progname && validateName(formData.progname) && formData.progch && formData.deptid && formData.plosCount && formData.plos.every(plo => plo.ploid && plo.plodescription && validateDes(plo.plodescription)) && validateCH(formData.progch);

  return (
    <Box display="flex" justifyContent="center" sx={{ mt: 4, mb: 4, ml: matches ? '280px' : 0, width: matches ? `calc(100% - 280px)` : '100%' }}>
      <Container maxWidth="lg">
        <Typography variant="h4" sx={{ mb: 3 }}>Programs</Typography>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button
            startIcon={showForm ? <Close /> : <Add />}
            variant="contained"
            style={{ backgroundColor: '#6c63ff', color: 'white' }}
            onClick={toggleForm}
            sx={{ borderRadius: 2, boxShadow: '0 3px 5px 2px rgba(105, 140, 255, .3)' }}
          >
            {showForm ? 'Cancel' : 'Add Program'}
          </Button>
        </Box>
        {showForm && (
          <Card elevation={12} sx={{ mb: 3, p: 2, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>{formData.progid ? 'Edit Program' : 'Add New Program'}</Typography>
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField name="progname" label="Program Name" value={formData.progname}
                  onChange={handleChange} required fullWidth InputLabelProps={{ shrink: true }}
                  error={formData.progname && !validateName(formData.progname)}
                  helperText={formData.progname && !validateName(formData.progname) && 'Only Use English Alphabets & Spaces'}
                />
                <TextField name="progch" label="Credit Hours" type="number" value={formData.progch}
                  onChange={handleChange} required fullWidth InputProps={{ inputProps: { min: 1, max: 150 } }}
                  InputLabelProps={{ shrink: true }}
                  error={formData.progch && !validateCH(formData.progch)}
                  helperText={formData.progch && !validateCH(formData.progch) && 'Credit Hours must be between 1 and 150'}
                />
                <TextField select name="deptid" label="Department" value={formData.deptid} onChange={handleChange} required fullWidth InputLabelProps={{ shrink: true }}>
                  {departments.map((dept) => (
                    <MenuItem key={dept.value} value={dept.value}>{dept.label}</MenuItem>
                  ))}
                </TextField>
                <TextField name="plosCount" label="Number of PLOs" type="number" value={formData.plosCount} onChange={handleChange} required fullWidth InputProps={{ inputProps: { min: 1, max: 20 } }} InputLabelProps={{ shrink: true }} />
                {formData.plos.map((plo, index) => (
                  <Box key={index} sx={{ display: 'flex', gap: 2 }}>
                    <TextField name={`ploid-${index}`} label="PLO ID" value={plo.ploid} onChange={(e) => handlePLOChange(index, 'ploid', e.target.value)} disabled required fullWidth InputLabelProps={{ shrink: true }} />
                    <TextField name={`plodescription-${index}`} label="PLO Description" value={plo.plodescription} onChange={(e) =>
                      handlePLOChange(index, 'plodescription', e.target.value)} required fullWidth InputLabelProps={{ shrink: true }}
                      error={plo.plodescription && !validateDes(plo.plodescription)}
                      helperText={plo.plodescription && !validateDes(plo.plodescription) && 'Only Use English Alphabets, Spaces and Allowed Punctuation: " \' . , ! ? ; : ( ) / -'}
                    />
                  </Box>
                ))}
                <Button type="submit" variant="contained" disabled={!allFieldsFilled} sx={{ mt: 2 }} style={{ backgroundColor: '#6c63ff', color: 'white' }} >
                  {formData.progid ? 'Update Program' : 'Create Program'}
                </Button>
                {formError && (
                  <Typography color="error" sx={{ mt: 2 }}>{formError}</Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        )}
        <TableContainer component={Paper} elevation={12} sx={{
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          border: '1px solid rgba(0,0,0,0.12)'
        }}>
          <Table aria-label="programs table">
            <TableHead sx={{ bgcolor: '#e0e0e0' }}>
              <TableRow sx={{ '& th': { fontWeight: 'bold' } }}>
                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Sr. No.</TableCell>
                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Program Name</TableCell>
                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Credit Hours</TableCell>
                <TableCell align="center">Department</TableCell>
                <TableCell align="center">PLOs</TableCell>
                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {programs.map((program, index) => (
                <React.Fragment key={program.progid}>
                  <TableRow>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{index + 1}</TableCell>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)', whiteSpace: 'nowrap' }}>{program.progname}</TableCell>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{program.progch}</TableCell>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)', whiteSpace: 'nowrap' }}>{departments.find(dept => dept.value === program.deptid)?.label}</TableCell>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>
                      <Button onClick={() => toggleOpen(program.progid)}>
                        {open[program.progid] ? 'Hide PLOs' : 'View PLOs'}
                      </Button>
                    </TableCell>
                    <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                      <IconButton color="primary" onClick={() => handleEdit(program)}>
                        <Edit />
                      </IconButton>
                      <IconButton color="secondary" onClick={() => handleDeleteClick(program)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                      <Collapse in={open[program.progid]} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            PLOs
                          </Typography>
                          <Table size="small" aria-label="plos">
                            <TableHead>
                              <TableRow sx={{ '& th': { fontWeight: 'bold' } }}>
                                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>PLO ID</TableCell>
                                <TableCell align="center">Description</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {program.plos.map((plo) => (
                                <TableRow key={plo.ploid}>
                                  <TableCell align="center" style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}>{plo.ploid}</TableCell>
                                  <TableCell align="center">{plo.plodescription}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog
          open={confirmDelete}
          onClose={() => setConfirmDelete(false)}
        >
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {confirmMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDelete(false)} color="primary">
              Cancel
            </Button>
            {!confirmMessage.includes('in use') && (
              <Button onClick={handleDelete} color="primary">
                Confirm
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

function formatInput(input) {
  let formattedInput = input.trim();
  formattedInput = formattedInput.replace(/\s+/g, ' ');
  return formattedInput.toUpperCase();
}

export default ProgramPage;
