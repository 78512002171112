import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const setAssessmentWeightages = (formData) => {
    return axios.post(`${baseURL}/assessmentweightages`, formData);
};

const getAssignedCoursesByTeacher = (teachid) => {
    return axios.get(`${baseURL}/assessmentweightages/courses/teacher/${teachid}`);
}

const assessmentWeightageService = {
    setAssessmentWeightages,
    getAssignedCoursesByTeacher
};

export default assessmentWeightageService;
